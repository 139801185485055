import { isString } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  TableBody as MaterialUiTableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';

import { Checkbox } from '../Checkbox';

export type TableBodyParams<Data extends Record<string, unknown>> = {
  data: Data[];
  rowKey: keyof Data;
  headers: (string | { label: string; sort?: boolean; value: string })[];
  mapRow: (data: Data) => React.ReactNode[];
  mapRowLink?: (data: Data) => string;
  selectRowProps?: {
    onAdd: (_ids: string[]) => void;
    onRemove: (_ids: string[]) => void;
    selected: string[];
  };
  isLoading?: boolean;
  activeSort?: string;
  sortDirection?: 'asc' | 'desc';
  onClickSort?: (value: string) => void;
};

const HeaderCheckbox = ({
  onChange,
  value,
}: {
  onChange: (value: boolean) => void;
  value: boolean;
}) => (
  <TableCell>
    <Checkbox value={value} onChange={onChange} />
  </TableCell>
);

const SortHeader = ({
  label,
  activeSort,
  direction,
  onClick,
}: {
  label: string;
  activeSort: boolean;
  direction?: 'asc' | 'desc';
  onClick: () => void;
}) => (
  <TableCell key={label}>
    <TableSortLabel active={activeSort} direction={direction} onClick={onClick}>
      <Typography fontSize={'14px'} fontWeight={600}>
        {label}
      </Typography>
    </TableSortLabel>
  </TableCell>
);

const NoSortHeader = ({ label }: { label: string }) => (
  <TableCell key={label}>
    <Typography fontSize={'14px'} fontWeight={600}>
      {label}
    </Typography>
  </TableCell>
);

export const TableBody = <Data extends Record<string, unknown>>({
  data,
  mapRow,
  headers,
  rowKey,
  mapRowLink,
  selectRowProps,
  isLoading,
  activeSort,
  sortDirection,
  onClickSort,
}: TableBodyParams<Data>) => {
  const dataIds = data.map((element) => element._id) as string[];
  const onHeaderAdd = (value: boolean) => {
    if (!selectRowProps) {
      return;
    }
    const _ids = dataIds;
    if (value) {
      selectRowProps.onAdd(_ids);
    } else {
      selectRowProps.onRemove(_ids);
    }
  };
  const renderHeaderSelect = selectRowProps ? (
    <HeaderCheckbox
      value={dataIds.every((id) => selectRowProps.selected.includes(id))}
      onChange={onHeaderAdd}
    />
  ) : null;

  const renderBody = isLoading
    ? null
    : data.map((rowData) => (
        <TableRow
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          component={mapRowLink ? Link : (null as any)}
          to={mapRowLink ? mapRowLink(rowData) : undefined}
          key={`table-row-${rowData[rowKey]}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
        >
          {selectRowProps ? (
            <TableCell>
              <Checkbox
                value={selectRowProps.selected.includes(rowData._id as string)}
                onChange={(value) =>
                  value
                    ? selectRowProps.onAdd([rowData._id as string])
                    : selectRowProps.onRemove([rowData._id as string])
                }
              />
            </TableCell>
          ) : null}
          {mapRow(rowData).map((row, index) => {
            return (
              <TableCell key={`th-${index}`} component="th" scope="row">
                <Typography fontSize={'12px'}>{row}</Typography>
              </TableCell>
            );
          })}
        </TableRow>
      ));
  return (
    <>
      <TableHead>
        <TableRow>
          {renderHeaderSelect}
          {headers.map((header, index) =>
            isString(header) || !header.sort ? (
              <NoSortHeader
                key={`${header}-${index}`}
                label={isString(header) ? header : header.label}
              />
            ) : (
              <SortHeader
                key={`${header}-${index}`}
                label={header.label}
                direction={sortDirection}
                activeSort={activeSort === header.value}
                onClick={() => onClickSort && onClickSort(header.value)}
              />
            ),
          )}
        </TableRow>
      </TableHead>
      <MaterialUiTableBody>{renderBody}</MaterialUiTableBody>
    </>
  );
};
