import React, { useRef } from 'react';
import EmailEditor, { EditorRef } from 'react-email-editor';
import { useParams } from 'react-router-dom';

import { useWebNavigate } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { useGetTaskEntityHandler } from '@cannabis/account-portal-core/hooks/useGetTaskEntityHandler';
import { useMutationFormTasksHandlerEvent } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Loading } from '@tq1992/components/Loading';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

export const CreateEmailTemplate = () => (
  <Tabs options={['Create Email Template']} appBar={true}>
    <BaseEmailTemplate />
  </Tabs>
);

export const BaseEmailTemplate = ({ _id }: { _id?: string }) => {
  const emailEditorRef = useRef<EditorRef>(null);
  const navigate = useWebNavigate();
  const { data } = useGetTaskEntityHandler(
    'templates',
    [
      {
        property: '_id',
        compare: '==',
        match: _id as string,
      },
    ],
    { skip: !_id },
  );
  const fields =
    data?.type === 'email'
      ? [
          { label: 'Title', _id: 'title', value: data?.title },
          { label: 'HTML', _id: 'value', value: data?.value },
          { label: 'Editor JSON', _id: 'editorJSON', value: data?.editorJSON },
        ]
      : [
          { label: 'Title', _id: 'title' },
          { label: 'HTML', _id: 'value' },
          { label: 'Editor JSON', _id: 'editorJSON' },
        ];
  const event = _id ? 'editTemplate' : 'createTemplate';
  const { register, submit, setValue, getValues } = useMutationFormTasksHandlerEvent(
    event,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields as any,
    {
      resetOnSuccess: false,
      _id,
      type: 'email',
    },
  );

  const getDesign = () =>
    new Promise<string | void>((resolve) => {
      emailEditorRef.current?.saveDesign((design) => {
        if (design) {
          resolve(JSON.stringify(design));
        } else {
          resolve();
        }
      });
    });

  const getHtml = () =>
    new Promise((resolve) => {
      emailEditorRef.current?.exportHtml((data) => {
        resolve(data.html);
      });
    });

  const onReady = () => {
    if (data?.type === 'email') {
      data?.editorJSON && emailEditorRef.current?.loadDesign(JSON.parse(getValues().editorJSON));
    }
  };

  const renderedFields = fields.map((field) => {
    switch (field._id) {
      case 'value':
      case 'editorJSON':
        return null;
      default:
        return <TextField key={field._id} label={field.label} {...register(field._id)} />;
    }
  });
  return (
    <FormContainer
      title="Create Template"
      onSubmit={async (e) => {
        e?.preventDefault();
        setValue('editorJSON', await getDesign());
        setValue('value', await getHtml());
        await submit(e);
        if (!data) {
          navigate.Email();
        }
      }}
      submitButtonLabel={_id ? 'Update Template' : 'Create Template'}
      postFormChildren={<EmailEditor ref={emailEditorRef} onReady={onReady} />}
    >
      {renderedFields}
    </FormContainer>
  );
};

export const EditEmailTemplate = () => {
  const { _id } = useParams();
  const { isLoading } = useGetTaskEntityHandler('templates', [
    {
      property: '_id',
      compare: '==',
      match: _id as string,
    },
  ]);
  return (
    <Tabs options={['Edit Email Template']} appBar={true}>
      {isLoading ? <Loading /> : <BaseEmailTemplate _id={_id} />}
    </Tabs>
  );
};
