import { useWebNavigatorContext } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { Tabs } from '@tq1992/components/Tabs';

import { Journeys } from '../Journeys';
import { MessageHistory } from './MessageHistory';
import { InAppMessage } from './inAppMessages';
import { SendPush } from './sendPush';
import { AutomatedPush } from './sendPush/AutomatedMessages';
import { SendSms } from './sendSms';
import { SendEmail } from './sendToEmail';

export const Messaging = () => {
  const { pathname } = useWebNavigatorContext();
  return (
    <Tabs
      options={[
        { label: 'Email', value: '/email' },
        { label: 'SMS', value: '/sms' },
        { label: 'Push', value: '/push' },
        { label: 'In App', value: '/in-app-message' },
        { label: 'Automated', value: '/automated-messages' },
        { label: 'Journeys', value: '/journeys' },
        { label: 'History', value: '/message-history' },
      ]}
      appBar={true}
      link={true}
      value={pathname}
    >
      <SendEmail />
      <SendSms />
      <SendPush />
      <InAppMessage />
      <AutomatedPush />
      <Journeys />
      <MessageHistory />
    </Tabs>
  );
};
