import { Tabs } from '@tq1992/components/Tabs';

import { InAppMessageTemplates } from './InAppMessageTemplates';

export const InAppMessage = () => {
  return (
    <Tabs options={['In App Message Templates']}>
      <InAppMessageTemplates />
    </Tabs>
  );
};
