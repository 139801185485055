import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';

import { WebNavigateContextProvider } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { Button } from '@tq1992/components/Button';
import Iconify from '@tq1992/components/minimal-main/components/iconify';
import { AppRoutes, IntializeAuthParams } from '@tq1992/sso-client';

import { Page, getRootPath, navigationRoutes } from '../../AccountPortalAppConfigs';
import { AdminUsers, CreateAdminUser } from './AdminUsers';
import { ClientOrganizations, CreateOrganization } from './ClientOrganizations';
import { CreateCustomerTenant } from './CustomerUsers/CreateCustomerTenant';
import { CreateCustomerUser } from './CustomerUsers/CreateCustomerUser';
import { CustomerUsers } from './CustomerUsers/CustomerUsers';
import { Dashboard } from './Dashboard';
import { Integrations } from './Integrations';
import { EditJourney } from './Journeys/EditJourney';
import { CreateLocation, Locations } from './Locations';
import { Logs } from './Logs';
import { Messaging } from './Messaging';
import { EditAutomatedMessage } from './Messaging/EditAutomatedMessage';
import { CreateInAppMessageTemplate } from './Messaging/inAppMessages/EditInAppMessageTemplate';
import { CreatePushTemplate, EditPushTemplate } from './Messaging/sendPush/EditPushTemplate';
import { CreateSmsTemplate, EditSmsTemplate } from './Messaging/sendSms/EditSmsTemplate';
import { CreateEmailTemplate, EditEmailTemplate } from './Messaging/sendToEmail/EditEmailTemplate';
import { Onboarding } from './Onboarding';
import { CreateOnboardingTemplate, EditOnboardingTemplate } from './Onboarding/EditOnboarding';
import { Orders } from './Orders';
import { PageContainer } from './PageContainer';
import { CreateProduct, Products } from './Products';
import { Profiles } from './Profiles';
import { CreateProfile } from './Profiles/CreateProfile';
import { CreateSegment } from './Profiles/CreateSegment';
import { Profile } from './Profiles/Profile';
import { Projects } from './Projects';
import { App } from './Projects/App';
import { CreateApp } from './Projects/CreateApp';
import { CreateProject } from './Projects/CreateProject';
import { Project } from './Projects/Project';
import { Sidebar } from './Sidebar';
import { SignInPage } from './SignInPage';

export const ButtonLinkToPage = ({
  to,
  iconify,
  label,
  dynamicTo = '',
}: {
  to?: Page;
  dynamicTo?: string;
  iconify?: string;
  label: string;
}) => (
  <Link to={to ? getRootPath(to) : dynamicTo} style={{ textDecoration: 'none' }}>
    <Button>
      {iconify ? <Iconify icon={iconify} style={{ marginRight: '4px' }} /> : null}
      {label}
    </Button>
  </Link>
);

export const Navigation = ({
  config,
  logo,
}: {
  config: IntializeAuthParams;
  logo?: JSX.Element;
}) => {
  return (
    <BrowserRouter>
      <WebNavigateContextProvider>
        <AppRoutes
          config={config}
          signInPage={(onSignIn) => (
            <PageContainer>
              <SignInPage onSignIn={onSignIn} />
            </PageContainer>
          )}
          protectedRoutes={
            <PageContainer sidebar={<Sidebar logo={logo} />}>
              <Routes>
                <Route path={navigationRoutes.Dashboard} element={<Dashboard />} />
                <Route path={navigationRoutes.Email} element={<Messaging />} />
                <Route path={navigationRoutes.Sms} element={<Messaging />} />
                <Route path={navigationRoutes.Push} element={<Messaging />} />
                <Route path={navigationRoutes.InAppMessage} element={<Messaging />} />
                <Route path={navigationRoutes.Journeys} element={<Messaging />} />
                <Route path={navigationRoutes.CreateJourney} element={<EditJourney />} />
                <Route path={navigationRoutes.MessageHistory} element={<Messaging />} />
                <Route path={navigationRoutes.AutomatedMessages} element={<Messaging />} />
                <Route path={navigationRoutes.Locations} element={<Locations />} />
                <Route path={navigationRoutes.Orders} element={<Orders />} />
                <Route path={navigationRoutes.Products} element={<Products />} />
                <Route path={navigationRoutes.AdminUsers} element={<AdminUsers />} />
                <Route path={navigationRoutes.CustomerUsers} element={<CustomerUsers />} />
                <Route path={navigationRoutes.Organizations} element={<ClientOrganizations />} />
                <Route path="*" element={<Navigate replace to="/dashboard" />} />
                <Route path={navigationRoutes.Integrations} element={<Integrations />} />
                <Route path={navigationRoutes.Logs} element={<Logs />} />
                <Route path={navigationRoutes.Profiles} element={<Profiles />} />
                <Route path={navigationRoutes.Segments} element={<Profiles />} />
                <Route path={navigationRoutes.CreateProfile} element={<CreateProfile />} />
                <Route path={navigationRoutes.CreateSegment} element={<CreateSegment />} />
                <Route path={navigationRoutes.CreateApp} element={<CreateApp />} />
                <Route path={navigationRoutes.CreateAdminUser} element={<CreateAdminUser />} />
                <Route
                  path={navigationRoutes.CreateCustomerUser}
                  element={<CreateCustomerUser />}
                />
                <Route
                  path={navigationRoutes.CreateCustomerTenant}
                  element={<CreateCustomerTenant />}
                />
                <Route
                  path={navigationRoutes.CreateOrganization}
                  element={<CreateOrganization />}
                />
                <Route path={navigationRoutes.CreateProduct} element={<CreateProduct />} />
                <Route path={navigationRoutes.CreateLocation} element={<CreateLocation />} />
                <Route path={navigationRoutes.Profile} element={<Profile />} />
                <Route path={navigationRoutes.EmailTemplate} element={<EditEmailTemplate />} />
                <Route path={navigationRoutes.Journey} element={<EditJourney />} />
                <Route path={navigationRoutes.Projects} element={<Projects />} />
                <Route path={navigationRoutes.CreateProject} element={<CreateProject />} />
                <Route path={navigationRoutes.Project} element={<Project />} />
                <Route path={navigationRoutes.App} element={<App />} />
                <Route
                  path={navigationRoutes.AutomatedMessage}
                  element={<EditAutomatedMessage />}
                />
                <Route
                  path={navigationRoutes.CreateEmailTemplate}
                  element={<CreateEmailTemplate />}
                />
                <Route path={navigationRoutes.Onboarding} element={<Onboarding />} />
                <Route
                  path={navigationRoutes.CreateOnboardingTemplate}
                  element={<CreateOnboardingTemplate />}
                />
                <Route
                  path={navigationRoutes.OnboardingTemplate}
                  element={<EditOnboardingTemplate />}
                />
                <Route path={navigationRoutes.CreateSmsTemplate} element={<CreateSmsTemplate />} />
                <Route
                  path={navigationRoutes.CreatePushTemplate}
                  element={<CreatePushTemplate />}
                />
                <Route
                  path={navigationRoutes.CreateInAppMessageTemplate}
                  element={<CreateInAppMessageTemplate />}
                />
                <Route path={navigationRoutes.SmsTemplate} element={<EditSmsTemplate />} />
                <Route path={navigationRoutes.PushTemplate} element={<EditPushTemplate />} />
              </Routes>
            </PageContainer>
          }
        />
      </WebNavigateContextProvider>
    </BrowserRouter>
  );
};
