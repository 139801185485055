import { useGetProfileResolversHandler } from '@cannabis/core/src/api/hooks/useGetProjectsResolvers';
import { useGetTaskHandlerEvent } from '@cannabis/core/src/api/hooks/useGetTaskEntityHandler';

import { useAppOrganizationContext } from '../contexts/AppOrganization';
import { useGetTaskEntityHandler } from './useGetTaskEntityHandler';

export const useProfiles = (params?: {
  orderBy: string;
  direction: 'asc' | 'desc';
  withinMilesOfBoston?: number;
}) => {
  const { organization } = useAppOrganizationContext();
  const getTagOptionsResult = useGetTaskHandlerEvent({
    event: 'getTagOptions',
    organizationId: organization._id,
    args: {},
  });
  return {
    profilesPaginationProps: useGetProfileResolversHandler({
      organizationId: organization._id,
      resolver: 'getProfiles',
      orderBy: params?.orderBy || 'lastUpdated',
      direction: params?.direction || 'desc',
      pagination: {
        limit: 10,
      },
      mongoQuery: params?.withinMilesOfBoston
        ? {
            geoPoint: {
              $geoWithin: {
                $centerSphere: [[42.361145, -71.057083], params.withinMilesOfBoston / 3963.2],
              },
            },
          }
        : undefined,
    }),
    getTagOptionsResult,
  };
};

export const useProfile = (_id: string) =>
  useGetTaskEntityHandler('profiles', [{ property: '_id', compare: '==', match: _id }]);

export const useGetProfileResolver = (
  params: Omit<Parameters<typeof useGetProfileResolversHandler>[0], 'organizationId'>,
) => {
  const { organization } = useAppOrganizationContext();
  return useGetProfileResolversHandler({
    ...params,
    organizationId: organization._id,
  });
};
