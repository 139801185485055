import { omit } from 'lodash';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useGetProfileResolver } from '@cannabis/account-portal-core/hooks/useGetTaskEntityHandler';
import { useSetDocumentHandlerEventFormFields } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { CenterGrid } from '@tq1992/components/CenterGrid';
import { FormContainer } from '@tq1992/components/FormContainer';
import { JsonViewer } from '@tq1992/components/JsonViewer';
import { Loading } from '@tq1992/components/Loading';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

const EditProfile = ({ _id }: { _id: string }) => {
  const { data: allProfiles } = useGetProfileResolver({
    resolver: 'getProfiles',
    queries: [
      {
        property: '_id',
        compare: '==',
        match: _id || '',
      },
    ],
    pagination: {
      limit: 1,
    },
  });
  const data = allProfiles[0];
  const { register, submit, fields } = useSetDocumentHandlerEventFormFields('profiles', {
    successMessage: () => 'Profile Updated',
    resetOnSuccess: false,
    fields: [
      { label: 'Email', _id: 'email', schema: Yup.string() },
      { label: 'Phone Number', _id: 'phoneNumber', schema: Yup.string() },
      { label: 'First Name', _id: 'firstName', schema: Yup.string() },
      { label: 'Last Name', _id: 'lastName', schema: Yup.string() },
      { label: 'Country', _id: 'country', schema: Yup.string() },
    ],
    initialValues:
      omit(
        data || {},
        'created',
        'lastUpdated',
        'exists',
        'appNames',
        'projectName',
        'organizationId',
      ) || undefined,
  });

  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <>
      <FormContainer
        title="Edit Profile"
        onSubmit={submit}
        submitButtonLabel="Save User"
        clearOnSubmit={false}
      >
        {renderedFields}
        Project: {data?.projectName}
        <br />
        App: {data?.appNames}
      </FormContainer>
      <JsonViewer collapsed={false} src={data} />
    </>
  );
};

export const Profile = () => {
  const { _id } = useParams();
  const { isLoading } = useGetProfileResolver({
    resolver: 'getProfiles',
    queries: [
      {
        property: '_id',
        compare: '==',
        match: _id || '',
      },
    ],
    pagination: {
      limit: 1,
    },
  });
  return (
    <Tabs options={['Edit Profile']} appBar={true}>
      {isLoading ? (
        <CenterGrid>
          <Loading />
        </CenterGrid>
      ) : (
        <EditProfile _id={_id as string} />
      )}
    </Tabs>
  );
};
