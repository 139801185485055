import EmojiPicker from 'emoji-picker-react';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useWebNavigatorContext } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { useGetTaskEntityHandler } from '@cannabis/account-portal-core/hooks/useGetTaskEntityHandler';
import { useMutationFormTasksHandlerEvent } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { Grid } from '@mui/material';
import { Accordion } from '@tq1992/components/Accordion';
import { Container } from '@tq1992/components/Container';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Loading } from '@tq1992/components/Loading';
import { ApplePushNotificationPreview } from '@tq1992/components/PushNotificationPreview';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

export const CreatePushTemplate = () => (
  <Tabs options={['Create Push Template']} appBar={true}>
    <BasePushTemplate />
  </Tabs>
);

export const BasePushTemplate = ({ _id }: { _id?: string }) => {
  const navigate = useWebNavigatorContext();

  const { data } = useGetTaskEntityHandler(
    'templates',
    [
      {
        property: '_id',
        compare: '==',
        match: _id as string,
      },
    ],
    { skip: !_id },
  );
  const fields =
    data?.type === 'push'
      ? [
          { label: 'Template Title', _id: 'title', value: data?.title },
          { label: 'Title', _id: 'pushTitle', value: data?.pushTitle },
          { label: 'Message', _id: 'body', value: data?.body },
        ]
      : [
          { label: 'Template Title', _id: 'title' },
          { label: 'Title', _id: 'pushTitle' },
          { label: 'Message', _id: 'body' },
        ];
  const event = _id ? 'editTemplate' : 'createTemplate';
  const { register, submit, watch, setValue, getValues } = useMutationFormTasksHandlerEvent(
    event,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields as any,
    {
      _id,
      type: 'push',
    },
  );

  const renderedFields = fields.map((field) => {
    switch (field._id) {
      case 'pushTitle':
      case 'body':
        return (
          <React.Fragment key={field._id}>
            <TextField key={field._id} label={field.label} {...register(field._id)} />
            <Accordion header="Emoji Keyboard 🍃🔥💨">
              <EmojiPicker
                key={field._id}
                onEmojiClick={(emoji) => {
                  setValue(field._id, `${getValues(field._id)}${emoji.emoji}`);
                }}
                height={350}
                previewConfig={{ showPreview: false }}
              />
            </Accordion>
          </React.Fragment>
        );
    }
    return <TextField key={field._id} label={field.label} {...register(field._id)} />;
  });
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormContainer
          title={_id ? 'Edit Template' : 'Create Template'}
          onSubmit={async (e) => {
            await submit(e);
            navigate.Push();
          }}
          submitButtonLabel={_id ? 'Update Template' : 'Create Template'}
        >
          {renderedFields}
        </FormContainer>
      </Grid>
      <Grid item xs={6}>
        <Container noBackground={true}>
          <ApplePushNotificationPreview
            title={watch('pushTitle')}
            message={watch('body')}
            time={'Now'}
          />
        </Container>
      </Grid>
    </Grid>
  );
};

export const EditPushTemplate = () => {
  const { _id } = useParams();
  const { isLoading } = useGetTaskEntityHandler('templates', [
    {
      property: '_id',
      compare: '==',
      match: _id as string,
    },
  ]);
  return (
    <Tabs options={['Edit Push Template']} appBar={true}>
      {isLoading ? <Loading /> : <BasePushTemplate _id={_id} />}
    </Tabs>
  );
};
