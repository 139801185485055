import { getEmojiDataFromNative } from 'emoji-mart';
import emojiRegex from 'emoji-regex';
import _ from 'lodash';
import React from 'react';

import EmojiPicker from '@emoji-mart/react';

import AppleRegularPushNotificationPreview from './AppleRegularPushNotificationPreview';

const emojiUnicodeToColonsFormat = (message: string) => {
  let output = message;
  let match = null;
  const unicodeEmojiRegex = emojiRegex();
  while ((match = unicodeEmojiRegex.exec(output)) !== null) {
    const emoji = match[0];
    const emojiName = getEmojiDataFromNative(emoji);
    const a = output.substr(0, match.index);
    const b = output.substr(match.index + emoji.length);
    output = a + emojiName + b;
  }
  return output;
};

const checkIfEmojiPresent = (message: string) => {
  const unicodeEmojiRegex = emojiRegex();
  return unicodeEmojiRegex.test(message);
};

const parseEmojis = (message: string, platform: string, size: number) => {
  if (message) {
    const colonFormat = emojiUnicodeToColonsFormat(message);
    const colonEmojiRegex = /(:(?![\n])[+()#$@-\w]+:)/g;
    const res = colonFormat.split(colonEmojiRegex);
    if (res.length > 1) {
      return (
        <React.Fragment>
          {_.map(res, (element, i) => {
            if (colonEmojiRegex.test(element)) {
              const emoji = element.replace(/:/g, '');
              return (
                <EmojiPicker
                  emoji={emoji}
                  set={platform}
                  size={size || 16}
                  key={`emoji-${emoji}-${i}`}
                />
              );
            }
            return element;
          })}
        </React.Fragment>
      );
    }
  }
  return message;
};

export { emojiUnicodeToColonsFormat, parseEmojis, checkIfEmojiPresent };

export const ApplePushNotificationPreview = ({
  title,
  message,
  time,
}: {
  title: string;
  message: string;
  time: string;
}) => {
  return (
    <React.Fragment>
      <AppleRegularPushNotificationPreview
        {...{ appName: title || 'App Name', message, title: '', time }}
      />
    </React.Fragment>
  );
};
