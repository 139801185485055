import { Link, useParams } from 'react-router-dom';

import { getAppRoute, getCreateAppRoute } from '@cannabis/account-portal-core';
import { useProject } from '@cannabis/account-portal-core/hooks/useProject';
import { Grid, Typography } from '@mui/material';
import { Card } from '@tq1992/components/Card';
import { CenterGrid } from '@tq1992/components/CenterGrid';
import { Loading } from '@tq1992/components/Loading';
import { Tabs } from '@tq1992/components/Tabs';

import { ButtonLinkToPage } from '../Navigation';

export const Apps = ({ apps }: { apps: { name: string; _id: string }[] }) => {
  const cardsRenderd = apps?.map((element) => (
    <Link to={getAppRoute(element._id)} style={{ textDecoration: 'none' }}>
      <Card label={element.name} />
    </Link>
  ));

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          {cardsRenderd}
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export const Project = () => {
  const { _id } = useParams();
  const { data, isLoading } = useProject(_id as string);
  const appsRendered = data ? (
    <>
      <Apps apps={data.apps} />
    </>
  ) : null;
  return (
    <Tabs options={[`Project: ${data?.name}`]} appBar={true}>
      <>
        <Typography>
          {data?.name} - {data?._id}
        </Typography>
        <br />
        <ButtonLinkToPage
          dynamicTo={getCreateAppRoute(_id || '')}
          label="Create App"
          iconify="ic:round-add-circle"
        />{' '}
        <br />
        <br />
        {isLoading ? (
          <CenterGrid>
            <Loading />
          </CenterGrid>
        ) : (
          appsRendered
        )}
      </>
    </Tabs>
  );
};
