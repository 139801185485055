import { isArray, isString } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { AppBar } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MaterialUiTabs from '@mui/material/Tabs';

const isFlatOptions = (param: string[] | { value: string; label: string }[]): param is string[] => {
  return isString(param[0]);
};

export const Tabs = <Options extends string[]>({
  options,
  children,
  appBar = false,
  link,
  value: propValue,
}: {
  options: Options | { value: string; label: string }[];
  children: ReactNode[] | ReactNode;
  appBar?: boolean;
  tabIndex?: number;
  link?: boolean;
  value?: string;
}) => {
  const getInitialValue = () => {
    if (propValue) {
      return propValue;
    } else if (isFlatOptions(options)) {
      return options[0];
    } else {
      return options[0].value;
    }
  };
  const [value, setValue] = useState(getInitialValue());
  useEffect(() => {
    setValue(getInitialValue);
  }, [options]);
  const renderedTabOptions = isFlatOptions(options)
    ? options.map((label) => <Tab label={label} key={label} value={label} />)
    : options.map((option) => {
        const component = link ? { component: Link } : {};
        // Needed cuz tab types are wrong
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const Tab2 = Tab as any;
        return (
          <Tab2
            label={option.label}
            value={option.value}
            {...component}
            to={option.value}
            key={option.value}
          />
        );
      });
  const AppBarComponent = ({ children }: { children: React.ReactNode }) =>
    appBar ? (
      <AppBar color="secondary" position="static">
        {children}
      </AppBar>
    ) : (
      <>{children}</>
    );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentIndex = isFlatOptions(options)
    ? options.findIndex((option) => option === value)
    : options.findIndex((option) => option.value === value);
  return (
    <>
      <AppBarComponent>
        <MaterialUiTabs
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          aria-label="Tabs"
          TabIndicatorProps={{
            style: {},
          }}
        >
          {renderedTabOptions}
        </MaterialUiTabs>
      </AppBarComponent>
      <Box sx={{ padding: '32px', paddingTop: '10px' }}>
        {isArray(children) ? children[currentIndex] : children}
      </Box>
    </>
  );
};
