import { useOrders } from '@cannabis/account-portal-core/hooks/useOrders';
import { getShortDateWithSeconds } from '@cannabis/core/src/dateUtils';
import { Table, useSortTable } from '@tq1992/components/Table';
import { Tabs } from '@tq1992/components/Tabs';

export const Orders = () => {
  const { activeSort, sortDirection, onClickSort } = useSortTable({
    initalDirection: 'desc',
    initialSort: 'lastUpdated',
  });
  const { data, pagination, isLoading } = useOrders({
    orderBy: activeSort,
    direction: sortDirection,
  });
  return (
    <Tabs options={['Orders']} appBar={true}>
      <Table
        {...{ activeSort, sortDirection, onClickSort }}
        isLoading={isLoading}
        headers={[
          { label: '_id', value: '_id', sort: true },
          { label: 'UserId', value: 'user.userId', sort: true },
          { label: 'Last Name', value: 'user.lastName', sort: true },
          { label: 'Status', value: 'status', sort: true },
          'Total Qty',
          { label: 'Last Updated', value: 'lastUpdated', sort: true },
        ]}
        data={data || []}
        mapRow={({ _id, user, status, lastUpdated, items }) => [
          _id,
          user.userId,
          user.lastName,
          status,
          items?.reduce<number>((result, item) => {
            return item.qty + result;
          }, 0),
          getShortDateWithSeconds(lastUpdated),
        ]}
        rowKey="_id"
        pagination={pagination}
      />
    </Tabs>
  );
};
