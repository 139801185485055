import pretty from 'pretty';
import React from 'react';
import * as Yup from 'yup';

import {
  Fields,
  useMutationFormTasksHandlerEvent,
} from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { Grid } from '@mui/material';
import { Button } from '@tq1992/components/Button';
import { DateTimePicker } from '@tq1992/components/DateTimePicker';
import { FormContainer } from '@tq1992/components/FormContainer';
import { TextField } from '@tq1992/components/TextField';

import { EmailPreview } from './EmailPreview';

export const SendEmailToOne = () => {
  const fields: Fields['sendEmail'] = [
    { label: 'To', _id: 'to' },
    { label: 'From', _id: 'from' },
    { label: 'Subject', _id: 'subject' },
    { label: 'HTML', _id: 'html' },
    { label: 'Text', _id: 'text' },
    { label: 'Date/Time', _id: 'date', schema: Yup.string() },
  ];
  const { register, submit, watch, setValue } = useMutationFormTasksHandlerEvent(
    'sendEmail',
    fields,
  );
  const html = watch('html');

  const renderedFields = fields.map((field) => {
    switch (field._id) {
      case 'html':
        return (
          <React.Fragment key={field._id}>
            <Button
              onClick={() => {
                setValue('html', pretty(html || ''));
              }}
            >
              Format
            </Button>
            <TextField multiline key={field._id} label={field.label} {...register(field._id)} />
          </React.Fragment>
        );
      case 'date':
        return <DateTimePicker key={field._id} onChange={(value) => setValue(field._id, value)} />;
      default:
        return <TextField key={field._id} label={field.label} {...register(field._id)} />;
    }
  });
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        <h1 style={{ textAlign: 'center' }}>Preview</h1>
      </Grid>
      <Grid item xs={6}>
        <FormContainer title="Send Email" onSubmit={submit} submitButtonLabel="Send Email">
          {renderedFields}
        </FormContainer>
      </Grid>
      <Grid item xs={6}>
        <EmailPreview html={html} />
      </Grid>
    </Grid>
  );
};
