import { DateTime } from 'luxon';

export const getShortDateWithSeconds = (date?: string) =>
  date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : '';

export const getLuxonDate = (date: string, type: keyof typeof DateTime) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date ? DateTime.fromISO(date).toLocaleString((DateTime as any)[type]) : '';

export const getShortDate = (date?: string) =>
  date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT) : '';

export const getCurrencyFormat = (
  value: string | number,
  configs?: { language?: 'en-US'; currency?: 'USD' },
) => {
  const language = configs?.language || 'en-US';
  const currency = configs?.currency || 'USD';
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
  }).format(Number(value));
};
