import { useTaskHandlerPagination } from './useTaskHandlerPagination';

export const useLogs = (params?: { orderBy: string; direction: 'asc' | 'desc' }) => {
  const {
    data: logs,
    isLoading,
    pagination,
    filters,
    setFilters,
  } = useTaskHandlerPagination('tasks', {
    orderBy: params?.orderBy || '_id',
    direction: params?.direction || 'desc',
    pagination: {
      limit: 10,
    },
  });

  return {
    logs: logs || [],
    isLoading,
    pagination,
    filters,
    setFilters,
  };
};
