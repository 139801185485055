import { useUsers } from '@cannabis/account-portal-core/hooks/useCustomers';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { getLuxonDate } from '@cannabis/core/src/dateUtils';
import { Table, useSortTable } from '@tq1992/components/Table';
import { Tabs } from '@tq1992/components/Tabs';

import { ButtonLinkToPage } from '../Navigation';

const CustomersTable = () => {
  const { activeSort, sortDirection, onClickSort } = useSortTable({
    initalDirection: 'desc',
    initialSort: 'email',
  });
  const { data, isLoading, pagination } = useUsers({
    orderBy: activeSort,
    direction: sortDirection,
  });

  return (
    <>
      <ButtonLinkToPage to="CreateCustomerUser" label="Create Customer User" />
      <Table
        {...{ activeSort, sortDirection, onClickSort }}
        isLoading={isLoading}
        headers={[
          { label: 'Email', sort: true, value: 'email' },
          { label: 'Birthday', sort: true, value: 'birthday' },
          { label: 'Type', sort: true, value: 'type' },
          { label: 'TenantId', sort: true, value: 'tenantId' },
          { label: '_id', sort: true, value: '_id' },
          { label: 'First Name', sort: true, value: 'firstName' },
          { label: 'Last Name', sort: true, value: 'lastName' },
          { label: 'Phone Number', sort: true, value: 'phoneNumber' },
          { label: 'Email Verfied', sort: true, value: 'emailVerified' },
          { label: 'Phone Number Verfied', sort: true, value: 'phoneNumberVerified' },
        ]}
        data={data || []}
        mapRow={({
          email,
          birthday,
          type,
          tenantId,
          _id,
          firstName,
          lastName,
          phoneNumber,
          emailVerified,
          phoneNumberVerified,
        }) => [
          email,
          birthday && getLuxonDate(birthday, 'DATE_SHORT'),
          type,
          tenantId,
          _id,
          firstName,
          lastName,
          phoneNumber,
          emailVerified ? 'Yes' : 'No',
          phoneNumberVerified ? 'Yes' : 'No',
        ]}
        rowKey="_id"
        pagination={pagination}
      />
    </>
  );
};

const TenantTables = () => {
  const { data, isLoading, pagination } = useTaskHandlerPagination('customerTenants');
  return (
    <>
      <ButtonLinkToPage to="CreateCustomerTenant" label="Create Customer Tenant" />
      <Table
        isLoading={isLoading}
        headers={['Name', 'Tenant Id']}
        data={data || []}
        mapRow={({ name, tenantId }) => [name, tenantId]}
        rowKey="_id"
        pagination={pagination}
      />
    </>
  );
};

export const CustomerUsers = () => {
  return (
    <Tabs options={['Tenants', 'Customer Users']} appBar={true}>
      <TenantTables />
      <CustomersTable />
    </Tabs>
  );
};
