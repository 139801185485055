import React from 'react';

import { Button, Grid } from '@mui/material';

import { useDefaultAppleStyles } from './DefaultAppleStyles';

const AppleRegularPushNotificationPreview = ({
  appName,
  time,
  message,
  title,
  color,
}: {
  appName: string;
  time?: string;
  message: string;
  title: string;
  color?: string;
}) => {
  const classes = useDefaultAppleStyles();
  return (
    <Grid container direction="row" className={classes.root} justifyContent="flex-end">
      <Button className={classes.clickableNotification}>
        <Grid item xs={12} className={classes.cardNotification}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12} className={classes.header}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Grid item className={classes.notificationTitle} style={{ color: color }}>
                      {appName}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ height: '26px', overflow: 'hidden' }}>
                  {time}
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} className={classes.notificationTitle}>
              {title}
            </Grid> */}
            <Grid item xs={12} className={classes.notificationMessage}>
              {message}
            </Grid>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
};

export default AppleRegularPushNotificationPreview;
