import * as Yup from 'yup';

import { useMutationFormTasksHandlerEvent } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useSegmentsPagination } from '@cannabis/account-portal-core/hooks/useSegments';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@tq1992/components/DateTimePicker';
import { FormContainer } from '@tq1992/components/FormContainer';

import { SegmentsSelectTable } from '../SegmentsSelectTable';

export const SendSmsToSegment = () => {
  const props = useSegmentsPagination();
  const { register, submit, fields, setValue } = useMutationFormTasksHandlerEvent(
    'sendSmsToSegments',
    [
      { label: 'Message', _id: 'message' },
      { label: 'Date', _id: 'date', schema: Yup.string() },
    ],
    { segments: props.selectRow.selected },
  );

  const renderedFields = fields.map((field) => {
    switch (field._id) {
      case 'date':
        return <DateTimePicker key={field._id} onChange={(value) => setValue(field._id, value)} />;
      default:
        return <TextField key={field._id} label={field.label} {...register(field._id)} />;
    }
  });
  return (
    <>
      <FormContainer title="" onSubmit={submit} submitButtonLabel="Send Sms">
        {renderedFields}
      </FormContainer>
      <SegmentsSelectTable {...props} />
    </>
  );
};
