import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

export const Accordion = ({ children, header }: { children: JSX.Element; header: string }) => (
  <MuiAccordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      {' '}
      <Typography>{header}</Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </MuiAccordion>
);
