import * as React from 'react';
import { useState } from 'react';

import {
  FormLabel,
  ToggleButtonGroup as MaterialUiToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

const useToggleButtonGroup = <Value extends string | number>({
  initialValues,
}: {
  initialValues?: Value[];
}) => {
  const [values, setValues] = useState(initialValues || []);
  return {
    values,
    setValues,
  };
};

export const ToggleButtonGroup = <Value extends string | number>({
  label,
  options,
  initialValues = [],
  onChange,
}: {
  label?: string;
  options: { label: string; value: Value }[];
  initialValues?: Value[];
  onChange: (newValue: Value[]) => void;
}) => {
  const { setValues, values } = useToggleButtonGroup({ initialValues });
  console.log('values', values);
  return (
    <>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <MaterialUiToggleButtonGroup
        value={values}
        onChange={(event, newValues) => {
          setValues(newValues);
          onChange(newValues);
        }}
        aria-label="text formatting"
      >
        {options.map(({ label, value }) => (
          <ToggleButton value={value} aria-label={label}>
            {label}
          </ToggleButton>
        ))}
      </MaterialUiToggleButtonGroup>
    </>
  );
};
