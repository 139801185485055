import { DateTime } from 'luxon';

import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { Grid } from '@mui/material';
import { Tabs } from '@tq1992/components/Tabs';
import AppWidgetSummary from '@tq1992/components/minimal-main/sections/@dashboard/app/AppWidgetSummary';

const TotalProfiles = () => {
  const result = useTaskHandlerPagination('profiles', {
    pagination: { limit: 0 },
    countOnly: true,
  });
  return (
    <AppWidgetSummary
      isLoading={result.isLoading}
      title="Total Profiles"
      total={result.pagination.count || 0}
      icon={'mdi:user-group'}
    />
  );
};

const NewProfilesToday = () => {
  const result = useTaskHandlerPagination('profiles', {
    countOnly: true,
    pagination: { limit: 0 },
    filters: [
      {
        property: 'created',
        compare: '>',
        match: DateTime.now().setZone('America/New_York').startOf('day').toUTC().toString(),
      },
    ],
  });
  return (
    <AppWidgetSummary
      isLoading={result.isLoading}
      title="New Profiles Today"
      total={result.pagination.count}
      color="info"
      icon={'mdi:user-group'}
    />
  );
};

const UniqueProfileSessionsToday = () => {
  const result = useTaskHandlerPagination('profiles', {
    countOnly: true,
    pagination: { limit: 0 },
    filters: [
      {
        property: 'lastActivity',
        compare: '>',
        match: DateTime.now().setZone('America/New_York').startOf('day').toUTC().toString(),
      },
    ],
  });
  return (
    <AppWidgetSummary
      isLoading={result.isLoading}
      title="Unique Profile Sessions Today"
      total={result.pagination.count}
      color="info"
      icon={'mdi:user-group'}
    />
  );
};

const TotalMessages = () => {
  const result = useTaskHandlerPagination('messages', {
    countOnly: true,
    pagination: { limit: 0 },
    filters: [
      {
        property: 'status',
        compare: '==',
        match: 'COMPLETE',
      },
    ],
  });
  return (
    <AppWidgetSummary
      isLoading={result.isLoading}
      title="Total Messages"
      total={result.pagination.count || 0}
      icon={'ic:baseline-message'}
    />
  );
};

const TotalMessagesSentToday = () => {
  const result = useTaskHandlerPagination('messages', {
    countOnly: true,
    pagination: { limit: 0 },
    filters: [
      {
        property: 'date',
        compare: '>',
        match: DateTime.now().setZone('America/New_York').startOf('day').toUTC().toString(),
      },
      {
        property: 'status',
        compare: '==',
        match: 'COMPLETE',
      },
    ],
    queryCombineOperation: '$and',
  });
  return (
    <AppWidgetSummary
      isLoading={result.isLoading}
      title="Messages Sent Today"
      total={result.pagination.count || 0}
      color="info"
      icon={'ic:baseline-message'}
    />
  );
};

const TotalOrders = () => {
  const result = useTaskHandlerPagination('orders', {
    pagination: { limit: 0 },
    countOnly: true,
  });
  return (
    <AppWidgetSummary
      isLoading={result.isLoading}
      title="Total Orders"
      total={result.pagination.count || 0}
      color="warning"
      icon={'ic:outline-shopping-cart'}
    />
  );
};

const TotalOrdersToday = () => {
  const result = useTaskHandlerPagination('orders', {
    pagination: { limit: 0 },
    countOnly: true,
    filters: [
      {
        property: 'created',
        compare: '>',
        match: DateTime.now().setZone('America/New_York').startOf('day').toUTC().toString(),
      },
    ],
  });
  return (
    <AppWidgetSummary
      isLoading={result.isLoading}
      title="Orders Today"
      total={result.pagination.count || 0}
      color="warning"
      icon={'ic:outline-shopping-cart'}
    />
  );
};

const TotalOrdersThisWeek = () => {
  const result = useTaskHandlerPagination('orders', {
    pagination: { limit: 0 },
    countOnly: true,
    filters: [
      {
        property: 'created',
        compare: '>',
        match: DateTime.now().setZone('America/New_York').startOf('week').toUTC().toString(),
      },
    ],
  });
  return (
    <AppWidgetSummary
      isLoading={result.isLoading}
      title="Orders This Week"
      total={result.pagination.count || 0}
      color="warning"
      icon={'ic:outline-shopping-cart'}
    />
  );
};

export const Dashboard = () => {
  return (
    <Tabs options={['User Activity', 'Messaging', 'Sales']} appBar={true}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <TotalProfiles />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <NewProfilesToday />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <UniqueProfileSessionsToday />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <TotalMessages />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TotalMessagesSentToday />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <TotalOrders />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TotalOrdersToday />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TotalOrdersThisWeek />
        </Grid>
      </Grid>
    </Tabs>
  );
};
