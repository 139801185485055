import Case from 'case';
import * as Yup from 'yup';

import { useAppOrganizationContext } from '@cannabis/account-portal-core/contexts/AppOrganization';
import { useSetDocumentHandlerEventFormFields } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useProducts } from '@cannabis/account-portal-core/hooks/useProducts';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { useMutationHandlerEvent } from '@cannabis/core/src/api/hooks/useSetMutationHandlerEvent';
import { getCurrencyFormat, getShortDateWithSeconds } from '@cannabis/core/src/dateUtils';
import { Category } from '@cannabis/functions/entities/products';
import { Chip } from '@mui/material';
import { Button } from '@tq1992/components/Button';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Loading } from '@tq1992/components/Loading';
import { Table, useSortTable } from '@tq1992/components/Table';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

import { ButtonLinkToPage } from './Navigation';

const productColors: Record<Category, string> = {
  concentrate: 'orange',
  edible: 'purple',
  flower: 'green',
  gear: 'blue',
  tincture: 'brown',
  topical: 'DarkGoldenRod',
  vaporizer: 'red',
  'pre-roll': 'DarkCyan',
};

export const CreateProduct = () => {
  const props = useTaskHandlerPagination('locations', {
    orderBy: 'created',
    direction: 'desc',
    pagination: {
      limit: 10,
    },
  });
  const { fields, register, submit } = useSetDocumentHandlerEventFormFields('products', {
    successMessage: (params) => `Product ${params?.title} Created`,
    fields: [{ label: 'Title', _id: 'title', schema: Yup.string().required() }],
    defaultArgs: {
      _id: '2',
      locations: props.selectRow.selected,
      public: true,
      prices: ['35', '40.00', '75.00'],
      options: ['1/28', '1/8', '1/2'],
      categories: ['flower'],
    },
  });
  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <Tabs options={['Create Product']} appBar={true}>
      <>
        <FormContainer onSubmit={submit} submitButtonLabel="Create Product">
          {renderedFields}
        </FormContainer>
        <Table
          isLoading={props.isLoading}
          data={props.data || []}
          headers={['Title']}
          mapRow={({ title }) => [title]}
          rowKey="_id"
          selectRowProps={props.selectRow}
          pagination={props.pagination}
        />
      </>
    </Tabs>
  );
};

export const ImportJaneRootsProductsButton = () => {
  const { organization } = useAppOrganizationContext();
  const {
    mutate,
    mutateArgs: { isLoading, data },
  } = useMutationHandlerEvent('importHeartJaneProducts', organization._id);
  return (
    <>
      <Button
        onClick={() =>
          mutate({ event: 'importHeartJaneProducts', args: {}, organizationId: organization._id })
        }
      >
        <>Import Heart Jane Products {isLoading ? <Loading /> : null}</>
      </Button>
      {data ? <br /> : null}
      {JSON.stringify(data)}
    </>
  );
};

export const Products = () => {
  const { activeSort, sortDirection, onClickSort } = useSortTable({
    initalDirection: 'desc',
    initialSort: 'lastUpdated',
  });
  const { data, pagination, isLoading, isFetching, setFilters } = useProducts({
    orderBy: activeSort,
    direction: sortDirection,
  });

  return (
    <Tabs options={['Products']} appBar={true}>
      <>
        <ButtonLinkToPage to="CreateProduct" label="Create Product" iconify="ic:round-add-circle" />{' '}
        <br />
        <br />
        <ImportJaneRootsProductsButton />
        <Table
          activeSort={activeSort}
          sortDirection={sortDirection}
          onClickSort={onClickSort}
          filters={{
            onAdd: (newFilters) =>
              setFilters(
                newFilters.map(({ property, compare, match }) => ({
                  property: String(property),
                  compare,
                  match,
                })),
              ),
            keyLabel: 'Key',
            valueLabel: 'Value',
            option: {
              keyOptions: [
                { label: 'Public', value: 'public' },
                { label: 'Type', value: 'type' },
                { label: 'THC', value: 'thc' },
                { label: 'CBD', value: 'cbd' },
              ],
              valueOptions: {
                public: {
                  options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ],
                  operators: ['=='],
                },
                type: {
                  options: [
                    { label: 'Flower', value: 'flower' },
                    { label: 'Edible', value: 'edible' },
                    { label: 'Vaporizer', value: 'vaporizer' },
                    { label: 'Concentrate', value: 'concentrate' },
                    { label: 'Topical', value: 'topical' },
                    { label: 'Pre-Roll', value: 'pre-roll' },
                    { label: 'Tincture', value: 'tincture' },
                    { label: 'Gear', value: 'gear' },
                  ],
                  operators: ['=='],
                },
                thc: {
                  options: [],
                  operators: ['>', '<'],
                },
                cbd: {
                  options: [],
                  operators: ['>', '<'],
                },
              },
            },
          }}
          isLoading={isLoading || isFetching}
          headers={[
            'Preview',
            { label: 'Title', value: 'title', sort: true },
            { label: 'Public', value: 'public', sort: true },
            'Amount',
            'Prices',
            { label: 'Type', value: 'type', sort: true },
            { label: 'THC', value: 'thc', sort: true },
            { label: 'CBD', value: 'cbd', sort: true },
            { label: 'Last Updated', value: 'lastUpdated', sort: true },
            { label: '_id', value: '_id', sort: true },
          ]}
          data={data || []}
          mapRow={(product) => [
            product.images ? <img alt="product" src={product.images[0]} height="50px" /> : null,
            product.title,
            product.public ? 'Yes' : 'No',
            product.options?.map((option) => <>{option},</>),
            product.prices?.map((price) => (
              <>
                <br />
                {getCurrencyFormat(price)}
              </>
            )),
            <Chip
              label={Case.capital(product.type)}
              sx={{ backgroundColor: productColors[product.type], color: 'white' }}
            />,
            product.thc ? `${product.thc}%` : '',
            product.cbd ? `${product.cbd}%` : '',
            getShortDateWithSeconds(product.lastUpdated),
            product._id,
          ]}
          rowKey="_id"
          pagination={pagination}
        />
      </>
    </Tabs>
  );
};
