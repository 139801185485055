import { Tabs } from '@tq1992/components/Tabs';

import { EmailTemplates } from './EmailTemplates';
import { SendEmailToOne } from './SendEmailToOne';
import { SendEmailToSegment } from './SendToSegmentEmail';

export const SendEmail = () => {
  return (
    <Tabs options={['Templates', 'Send To One User', 'Send To Segment']}>
      <EmailTemplates />
      <SendEmailToOne />
      <SendEmailToSegment />
    </Tabs>
  );
};
