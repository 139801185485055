import React from 'react';

import { getAutomatedMessageRoute } from '@cannabis/account-portal-core/AccountPortalAppConfigs';
import { useAppOrganizationContext } from '@cannabis/account-portal-core/contexts/AppOrganization';
import { useAccountPortalSnackbar } from '@cannabis/account-portal-core/hooks/useAccountPortalSnackbar';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { appApi } from '@cannabis/core/src/api/accountPortalApi';
import { getLuxonDate } from '@cannabis/core/src/dateUtils';
import { IconButton } from '@tq1992/components/IconButton';
import { Select, useSelect } from '@tq1992/components/Select';
import { Table } from '@tq1992/components/Table';

import { CreateAbanondedCart } from './CreateAbanondedCart';
import { CreateAutomatedPush } from './CreateAutomatedPush';
import { CreateInAppMessage } from './CreateInAppMessage';

const daysOfTheWeekMapping = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

export const AutomatedPush = () => {
  const { data, pagination, isLoading } = useTaskHandlerPagination('automatedMessages', {
    orderBy: 'created',
    direction: 'desc',
    pagination: { limit: 10 },
  });
  const [mutate] = appApi.endpoints.tasksHandler.useMutation();
  const { enqueueSnackbar } = useAccountPortalSnackbar();
  const { organization } = useAppOrganizationContext();

  const onDelete = async (_id: string) => {
    await mutate({
      organizationId: organization._id,
      event: 'callAction',
      args: { name: 'deleteAutomatedMessage', args: { _id } },
    });
    enqueueSnackbar('Message Deleted');
  };

  const { options, onChange, value } = useSelect([
    {
      label: 'Daily Push',
      value: 'daily_push',
    },
    {
      label: 'Abandoned Cart',
      value: 'abandoned_cart',
    },
    {
      label: 'In App Message',
      value: 'in_app_message',
    },
  ]);

  const getCreateMessageType = () => {
    switch (value) {
      case 'daily_push':
        return <CreateAutomatedPush />;
      case 'abandoned_cart':
        return <CreateAbanondedCart />;
      case 'in_app_message':
        return <CreateInAppMessage />;
    }
  };
  return (
    <>
      <Select options={options} onChange={onChange} value={value} label={'Type'} />
      {getCreateMessageType()}
      <Table
        isLoading={isLoading}
        headers={['Type', 'Time', 'Title', 'Body', 'Days of the week', 'Last Updated', '_id', '']}
        data={data || []}
        mapRow={(document) => {
          switch (document.type) {
            case 'PUSH':
              const { type, date, title, body, daysOfTheWeek, lastUpdated, _id } = document;
              return [
                type,
                getLuxonDate(date, 'TIME_SIMPLE'),
                title,
                body,
                daysOfTheWeek?.map((day) => daysOfTheWeekMapping[day] + ' '),
                lastUpdated ? new Date(lastUpdated).toString() : '',
                _id,
                <IconButton
                  onClick={() => onDelete(_id)}
                  tooltip="Delete"
                  icon="ic:round-delete-sweep"
                />,
              ];
            case 'ABANDONED_CART':
              return [
                document.type,
                null,
                document.title,
                document.body,
                null,
                document.lastUpdated ? new Date(document.lastUpdated).toString() : '',
                document._id,
                <IconButton
                  onClick={() => onDelete(document._id)}
                  tooltip="Delete"
                  icon="ic:round-delete-sweep"
                />,
              ];
            case 'IN_APP_MESSAGE':
              return [
                document.type,
                null,
                document.title,
                document.body,
                null,
                document.lastUpdated ? new Date(document.lastUpdated).toString() : '',
                document._id,
                <IconButton
                  onClick={() => onDelete(document._id)}
                  tooltip="Delete"
                  icon="ic:round-delete-sweep"
                />,
              ];
            default:
              return [];
          }
        }}
        rowKey="_id"
        pagination={pagination}
        mapRowLink={(row) => getAutomatedMessageRoute(row._id)}
      />
    </>
  );
};
