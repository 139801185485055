import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import { AppConfigs, getAppConfig } from '@cannabis/core/src/getAppConfig';
import { appApiUrls } from '@cannabis/core/src/types';
import { AppRoutesParams } from '@tq1992/sso-client';

import { AccountPortalAppConfigs } from './AccountPortalAppConfigs';
import { AppContextProvider } from './AppContext';
import { useAppOrganizationContext } from './contexts/AppOrganization';
import { envServices } from './envValues';
import { getAuthMethods, initializeAccountPortalEnvConfigs } from './initialize';
import { getStore } from './store';

export * from './AccountPortalAppConfigs';

export const HandleAccountPortalApp = ({ env, App, localAuth }: AccountPortalAppConfigs) => {
  const [configs, setConfigs] = useState<{
    appConfigs: AppConfigs;
    store: ReturnType<typeof getStore>;
  }>();
  const [isLooadingConfigs, setIsLoadingConfigs] = useState(true);
  const { setOrganization } = useAppOrganizationContext();

  useEffect(() => {
    const run = async () => {
      try {
        const apiUrl = appApiUrls[env];
        const store = getStore(apiUrl);
        const { data: configsData } = await getAppConfig(env);
        setConfigs({ appConfigs: configsData, store });
        initializeAccountPortalEnvConfigs({
          service: envServices[env],
          baseUrl: configsData.apiUrl,
          firebaseJson: configsData.firebaseJson,
          ssoBaseUrl: configsData.ssoUrl,
          localAuth,
        });
        setIsLoadingConfigs(false);
      } catch (e) {
        console.log('error getting config', e);
      }
    };
    run();
  }, []);
  const config: AppRoutesParams['config'] = {
    ssoBaseUrl: configs?.appConfigs.ssoUrl || '',
    service: envServices[env],
    signIn: async ({ token, tenantId }) => {
      const { organization } = await getAuthMethods().signIn({ token, tenantId });
      console.log('signIn organization', organization);
      setOrganization(organization);
    },
    isSignedIn: async () => {
      const organization = await getAuthMethods().isSignedIn();
      console.log('isSignedIn organization', organization);
      if (organization) {
        setOrganization(organization);
        return true;
      } else {
        return false;
      }
    },
    signOut: getAuthMethods().signOut,
  };
  if (configs) {
    return (
      <Provider store={configs.store}>
        <App config={config} isLoadingConfigs={isLooadingConfigs} />
      </Provider>
    );
  } else {
    return <App config={config} isLoadingConfigs={isLooadingConfigs} />;
  }
};

export const AccountPortalApp = ({ env, App }: AccountPortalAppConfigs) => {
  return (
    <AppContextProvider>
      <HandleAccountPortalApp App={App} env={env} localAuth={env === 'local'} />
    </AppContextProvider>
  );
};
