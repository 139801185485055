import {
  useGetProfileResolversHandler,
  useGetProjectsResolversHandler,
} from '@cannabis/core/src/api/hooks/useGetProjectsResolvers';
// eslint-disable-next-line max-len
import { useGetTaskEntityHandler as useGetTaskEntityHandlerCore } from '@cannabis/core/src/api/hooks/useGetTaskEntityHandler';
import { EntityKey, TaskHandlers } from '@cannabis/functions/express/tasksRouter';

import { useAppOrganizationContext } from '../contexts/AppOrganization';

export const useGetTaskEntityHandler = <Entity extends EntityKey>(
  entity: Entity,
  queries?: Omit<
    Parameters<TaskHandlers['entityGetDocument']>[0],
    'entity' | 'organizationId'
  >['args']['queries'],
  options?: { skip?: boolean },
) => {
  const { organization } = useAppOrganizationContext();
  return useGetTaskEntityHandlerCore(
    {
      entity,
      organizationId: organization._id,
    },
    queries,
    options,
  );
};

export const useGetProjectResolver = (
  params: Omit<Parameters<typeof useGetProjectsResolversHandler>[0], 'organizationId'>,
) => {
  const { organization } = useAppOrganizationContext();
  return useGetProjectsResolversHandler({
    ...params,
    organizationId: organization._id,
  });
};

export const useGetProfileResolver = (
  params: Omit<Parameters<typeof useGetProfileResolversHandler>[0], 'organizationId'>,
) => {
  const { organization } = useAppOrganizationContext();
  return useGetProfileResolversHandler({
    ...params,
    organizationId: organization._id,
  });
};
