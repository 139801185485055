import { Env } from '@cannabis/core/src/types';
import { AppRoutesParams } from '@tq1992/sso-client';

export type AccountPortalEnvConfigs = {
  service: string;
  baseUrl: string;
  firebaseJson: Record<string, string>;
  ssoBaseUrl: string;
  localAuth?: boolean;
};

export type Page =
  | 'Dashboard'
  | 'Locations'
  | 'Orders'
  | 'Products'
  | 'AdminUsers'
  | 'CustomerUsers'
  | 'Organizations'
  | 'Integrations'
  | 'Logs'
  | 'Profiles'
  | 'Profile'
  | 'CreateProfile'
  | 'Projects'
  | 'Project'
  | 'App'
  | 'CreateProfile'
  | 'CreateSegment'
  | 'CreateLocation'
  | 'CreateProject'
  | 'CreateApp'
  | 'CreateAdminUser'
  | 'CreateCustomerUser'
  | 'CreateCustomerTenant'
  | 'CreateOrganization'
  | 'CreateProduct'
  | 'Segments'
  | 'Email'
  | 'Sms'
  | 'Push'
  | 'Journeys'
  | 'MessageHistory'
  | 'CreateJourney'
  | 'AutomatedMessages'
  | 'AutomatedMessage'
  | 'Journey'
  | 'CreateEmailTemplate'
  | 'EmailTemplate'
  | 'Onboarding'
  | 'CreateOnboardingTemplate'
  | 'OnboardingTemplate'
  | 'CreateSmsTemplate'
  | 'CreatePushTemplate'
  | 'SmsTemplate'
  | 'PushTemplate'
  | 'CreateInAppMessageTemplate'
  | 'InAppMessageTemplate'
  | 'InAppMessage';

const rootPaths = {
  Dashboard: '/dashboard',
  Locations: '/locations',
  Orders: '/orders',
  Products: '/products',
  AdminUsers: '/admin-users',
  CustomerUsers: '/customer-users',
  Organizations: '/organizations',
  Integrations: '/integrations',
  Logs: '/logs',
  Profiles: '/profiles',
  Projects: '/projects',
  App: '/apps',
  CreateProfile: '/profiles/create',
  CreateSegment: '/segments/create',
  CreateLocation: '/locations/create',
  CreateProject: '/projects/create',
  CreateAdminUser: '/admin-users/create',
  CreateCustomerUser: '/cusetomer-users/create',
  CreateCustomerTenant: '/tenants/create',
  CreateOrganization: '/organizations/create',
  CreateProduct: '/products/create',
  Segments: '/segments',
  Email: '/email',
  Sms: '/sms',
  Push: '/push',
  Journeys: '/journeys',
  MessageHistory: '/message-history',
  CreateJourney: '/create-journey',
  AutomatedMessages: '/automated-messages',
  CreateEmailTemplate: '/email-template/create',
  Onboarding: '/onboarding',
  CreateOnboardingTemplate: '/onboarding-template',
  CreateSmsTemplate: '/sms-template',
  CreatePushTemplate: '/push-template',
  CreateInAppMessageTemplate: '/in-app-message-template',
  InAppMessage: '/in-app-message',
};

export const getProfileRoute = (_id: string) => `${rootPaths.Profiles}/${_id}`;
export const getEmailTemplateRoute = (_id: string) =>
  `${rootPaths.AutomatedMessages}/email-template/${_id}`;
export const getJourneyRoute = (_id: string) => `${rootPaths.Journeys}/${_id}`;
export const getProjectRoute = (_id: string) => `${rootPaths.Projects}/${_id}`;
export const getAppRoute = (_id: string) => `${rootPaths.App}/${_id}`;
export const getCreateAppRoute = (projectId: string) =>
  `${rootPaths.Projects}/${projectId}/create-app`;
export const getAutomatedMessageRoute = (_id: string) => `${rootPaths.AutomatedMessages}/${_id}`;
export const getOnboardingTemplateRoute = (_id: string) =>
  `${rootPaths.CreateOnboardingTemplate}/${_id}`;
export const getSmsTemplateRoute = (_id: string) => `${rootPaths.CreateSmsTemplate}/${_id}`;
export const getPushTemplateRoute = (_id: string) => `${rootPaths.CreatePushTemplate}/${_id}`;
export const getInAppMessageTemplateRoute = (_id: string) =>
  `${rootPaths.CreateInAppMessageTemplate}/${_id}`;

export const getRootPath = (path: Page) => rootPaths[path as keyof typeof rootPaths];

export const navigationRoutes: Record<Page, string> = {
  ...rootPaths,
  Profile: getProfileRoute(':_id'),
  EmailTemplate: getEmailTemplateRoute(':_id'),
  Project: getProjectRoute(':_id'),
  Journey: getJourneyRoute(':_id'),
  App: getAppRoute(':_id'),
  CreateApp: getCreateAppRoute(':projectId'),
  AutomatedMessage: getAutomatedMessageRoute(':_id'),
  OnboardingTemplate: getOnboardingTemplateRoute(':_id'),
  SmsTemplate: getSmsTemplateRoute(':_id'),
  PushTemplate: getPushTemplateRoute(':_id'),
  InAppMessageTemplate: getInAppMessageTemplateRoute(':_id'),
};

export type PaginationProps = {
  page: number;
  handleSetPage: (page: number) => void;
  limit: number;
  handleSetLimit: (limit: number) => void;
  isLoading: boolean;
  count: number;
};

export interface AccountPortalAppConfigs {
  env: Env;
  App: (params: { config: AppRoutesParams['config']; isLoadingConfigs: boolean }) => JSX.Element;
  localAuth?: boolean;
}
