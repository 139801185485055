import { useState } from 'react';

import { Grid, TableContainer, TableRow } from '@mui/material';
import { Table as MaterialUiTable } from '@mui/material';

import { CenterGrid } from '../CenterGrid';
import { Container } from '../Container';
import { Loading } from '../Loading';
import { Filters, FiltersProps } from './Filters';
import { Pagination, PaginationProps } from './Pagination';
import { TableBody, TableBodyParams } from './TableBody';

export const useSortTable = ({
  initialSort,
  initalDirection,
}: {
  initialSort: string;
  initalDirection: 'asc' | 'desc';
}) => {
  const [activeSort, setActiveSort] = useState(initialSort);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(initalDirection);
  const onClickSort = (value: string) => {
    if (value === activeSort) {
      switch (sortDirection) {
        case 'asc':
          setSortDirection('desc');
          return;
        case 'desc':
          setSortDirection('asc');
          return;
      }
    } else {
      setActiveSort(value);
      setSortDirection('asc');
    }
  };
  return {
    activeSort,
    sortDirection,
    onClickSort,
  };
};

export const Table = <Data extends Record<string, unknown>>({
  data,
  mapRow,
  headers,
  rowKey,
  pagination,
  filters,
  mapRowLink,
  selectRowProps,
  isLoading,
  activeSort,
  sortDirection,
  onClickSort,
}: TableBodyParams<Data> & {
  pagination: PaginationProps;
  filters?: FiltersProps;
}) => {
  const filtersRender = filters ? (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Filters</h3>
          <Filters {...filters} />
        </Grid>
      </Grid>
    </Grid>
  ) : null;
  const body = isLoading ? (
    <TableRow>
      <CenterGrid>
        <Loading />
      </CenterGrid>
    </TableRow>
  ) : (
    <TableBody
      {...{
        data,
        mapRow,
        headers,
        rowKey,
        mapRowLink,
        selectRowProps,
        isLoading,
        activeSort,
        sortDirection,
        onClickSort,
      }}
    />
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Container>
          {filtersRender}
          <TableContainer>
            <MaterialUiTable aria-label="simple table" sx={{ padding: 0 }}>
              {body}
            </MaterialUiTable>
            {pagination ? <Pagination {...pagination} /> : null}
          </TableContainer>
        </Container>
      </Grid>
    </Grid>
  );
};
