import { useAppOrganizationContext } from '@cannabis/account-portal-core/contexts/AppOrganization';
import { useAccountPortalSnackbar } from '@cannabis/account-portal-core/hooks/useAccountPortalSnackbar';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { appApi } from '@cannabis/core/src/api/accountPortalApi';
import { IconButton } from '@tq1992/components/IconButton';
import { Table } from '@tq1992/components/Table';

import { ButtonLinkToPage } from '../Navigation';

const SegmentsTable = () => {
  const [mutate] = appApi.endpoints.tasksHandler.useMutation();
  const { enqueueSnackbar } = useAccountPortalSnackbar();
  const {
    data: profiles,
    pagination,
    isLoading,
    refetch,
  } = useTaskHandlerPagination('segments', {
    orderBy: 'created',
    direction: 'desc',
    pagination: {
      limit: 10,
    },
  });
  const { organization } = useAppOrganizationContext();
  const onDelete = async (segmentId: string) => {
    await mutate({
      organizationId: organization._id,
      event: 'deleteSegment',
      args: { _id: segmentId },
    });
    enqueueSnackbar('Segment Deleted');
    refetch();
  };
  if (!profiles) {
    return null;
  } else {
    return (
      <>
        <ButtonLinkToPage to="CreateSegment" label="Create Segment" />
        <Table
          isLoading={isLoading}
          pagination={pagination}
          headers={['Name', 'Filters', 'Last Updated', 'Created', '_id', '']}
          data={profiles}
          mapRow={({ name, filters, lastUpdated, created, _id }) => [
            name,
            JSON.stringify(filters),
            new Date(lastUpdated || '').toString(),
            new Date(created || '').toString(),
            _id,
            <IconButton
              onClick={() => onDelete(_id)}
              tooltip="Delete"
              icon="ic:round-delete-sweep"
            />,
          ]}
          rowKey="_id"
        />
      </>
    );
  }
};

export const Segments = () => <SegmentsTable />;
