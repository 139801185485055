import { difference, last, uniq } from 'lodash';
import { useState } from 'react';

type Direction = 'asc' | 'desc';

export const useSelectRow = (initialValues?: string[]) => {
  const [selected, selectRow] = useState<string[]>(initialValues || []);

  const onAdd = (_ids: string[]) => selectRow(uniq([..._ids, ...selected]));
  const onRemove = (_ids: string[]) => selectRow(difference(selected, _ids));
  return {
    selected,
    onAdd,
    onRemove,
  };
};

interface Pagination {
  limit: number;
  startAfter?: string | undefined;
}

export const usePagination = <DataType extends { _id: string }>({
  useQuery,
  organizationId,
  orderBy = '_id',
  pollingInterval,
  direction = 'asc',
  pagination,
  countOnly,
  queryCombineOperation,
  initialSelectedRows,
}: {
  organizationId: string;
  pagination?: Pagination;
  useQuery: (
    params: {
      pagination?: Pagination;
      organizationId: string;
      orderBy?: string;
      direction?: Direction;
      pollingInterval?: number;
      countOnly?: boolean;
      queryCombineOperation?: '$or' | '$and';
    },
    options: { pollingInterval?: number },
  ) => {
    data?: { data: DataType[]; count: number };
    isLoading: boolean;
    refetch: () => void;
    isFetching: boolean;
  };
  orderBy?: string;
  pollingInterval?: number;
  direction?: Direction;
  countOnly?: boolean;
  queryCombineOperation?: '$or' | '$and';
  initialSelectedRows?: string[];
}) => {
  const [page, setPage] = useState(0);
  const [startAfter, setStartAfter] = useState<Record<number, string>>({});
  const propLimit = pagination?.limit === 0 ? 0 : pagination?.limit || 10;
  const [limit, handleSetLimit] = useState(propLimit);

  const {
    data: result,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    {
      organizationId,
      pagination: {
        limit,
        startAfter: startAfter[page],
      },
      direction,
      orderBy,
      countOnly,
      queryCombineOperation,
    },
    { pollingInterval },
  );

  const data = result?.data;

  const selectRow = useSelectRow(initialSelectedRows);

  const handleSetPage = (newPage: number) => {
    setPage(newPage);
    const lastElement = last(data);
    if (newPage && !startAfter[newPage] && lastElement) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setStartAfter({ ...startAfter, [newPage]: (lastElement as any)[orderBy] || '' });
    }
  };
  return {
    data: data || [],
    isLoading,
    isFetching,
    refetch,
    pagination: {
      page,
      limit,
      handleSetLimit,
      count: result?.count,
      handleSetPage,
    },
    selectRow,
  };
};
