/* eslint-disable @typescript-eslint/no-explicit-any */
import { omit } from 'lodash';

import { AppConfigs } from '@cannabis/core/src/getAppConfig';
import { Direction } from '@cannabis/functions/crud/firebaseUtils/firebaseUtils';
import { User } from '@cannabis/functions/crud/model/types';
import { Integration } from '@cannabis/functions/entities/integrations';
import { Location } from '@cannabis/functions/entities/locations';
import { Order } from '@cannabis/functions/entities/orders/schemas';
import { Organization } from '@cannabis/functions/entities/organizations/organizationModel';
import { ActiveProduct, Product } from '@cannabis/functions/entities/products';
import { TasksSchema } from '@cannabis/functions/entities/tasks/tasksModel';
import { TasksArgs } from '@cannabis/functions/express/tasksRouter';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type Tags =
  | 'profiles'
  | 'logs'
  | 'segments'
  | 'locations'
  | 'products'
  | 'projects'
  | 'apps'
  | 'automatedMessages'
  | 'journeys'
  | 'users'
  | 'templates';

type StoreProduct = Omit<ActiveProduct, 'createdOn'>;

export const createAppApi = ({
  baseUrl,
  getHeaders,
}: {
  baseUrl: string;
  getHeaders?: () =>
    | Promise<{ token: string; tenantId: string } | void>
    | { token: string; tenantId: string }
    | void;
}) =>
  createApi({
    extractRehydrationInfo(action, { reducerPath }) {
      if (action.type === '__NEXT_REDUX_WRAPPER_HYDRATE__') {
        return action.payload[reducerPath];
      }
    },
    baseQuery: fetchBaseQuery({
      prepareHeaders: async (headers) => {
        const headerValues = getHeaders && (await getHeaders());
        if (headerValues) {
          headers.set('Authorization', headerValues.token);
          headers.set('TenantId', headerValues.tenantId);
        }
        return headers;
      },
    }),
    tagTypes: [
      'logs',
      'profiles',
      'projects',
      'locations',
      'products',
      'segments',
      'projects',
      'apps',
    ] as Tags[],
    endpoints: (builder) => ({
      getLocations: builder.query<
        Location[],
        { organizationId: string; limit: number; startAfter: string }
      >({
        query: ({ organizationId, startAfter, limit }) => ({
          url: `${baseUrl}/organizations/${organizationId}/locations/documents}`,
          method: 'POST',
          body: { query: { limit: limit, startAfter } },
        }),
      }),
      getLogs: builder.query<
        { data: TasksSchema[]; count: number },
        {
          organizationId: string;
          limit: number;
          startAfter: string;
          orderBy?: string;
          direction?: Direction;
        }
      >({
        query: ({ organizationId, startAfter, limit, orderBy, direction }) => ({
          url: `${baseUrl}/organizations/${organizationId}/tasks/documents`,
          method: 'POST',
          body: {
            query: {
              limit,
              startAfter,
              orderBy,
              direction,
            },
          },
        }),
        providesTags: ['logs'],
      }),
      getOrders: builder.query<
        { data: Order[]; count: number },
        {
          organizationId: string;
          limit: number;
          startAfter: string;
          orderBy?: string;
          direction?: Direction;
        }
      >({
        query: ({ organizationId, limit, startAfter, orderBy, direction }) => ({
          url: `${baseUrl}/organizations/${organizationId}/orders/documents`,
          method: 'POST',
          body: {
            query: {
              limit,
              startAfter,
              orderBy,
              direction,
            },
          },
        }),
      }),
      getProducts: builder.query<
        { data: Product[]; count: number },
        { organizationId: string; limit: number; startAfter: string }
      >({
        query: ({ organizationId, startAfter, limit }) => ({
          url: `${baseUrl}/organizations/${organizationId}/products/documents`,
          method: 'POST',
          body: {
            query: {
              limit,
              startAfter,
            },
          },
        }),
      }),
      getCustomerUsers: builder.query<{ data: User[]; count: number }, { organizationId: string }>({
        query: ({ organizationId }) => ({
          url: `${baseUrl}/organizations/${organizationId}/customer-users`,
          method: 'GET',
        }),
      }),
      getAppConfigs: builder.query<AppConfigs, string>({
        query: (url) => ({
          url,
          method: 'GET',
        }),
      }),
      getOrganizations: builder.query<
        { data: Organization[]; count: number },
        { organizationId: string }
      >({
        query: () => ({
          url: `${baseUrl}/organizations/all`,
          method: 'GET',
        }),
      }),
      createOrganization: builder.mutation<
        void,
        { name: string; organizationId: string; user: { email: string; password: string } }
      >({
        query: ({ name, organizationId, user }) => ({
          url: `${baseUrl}/organizations`,
          method: 'POST',
          body: { name, user, organizationId },
        }),
      }),
      getOrganization: builder.query<Organization, void>({
        query: () => ({
          url: `${baseUrl}/organization`,
          method: 'GET',
        }),
      }),
      getIntegrations: builder.query<
        { data: Integration[]; count: number },
        {
          organizationId: string;
          limit?: number;
          startAfter?: string;
          orderBy?: string;
          direction?: Direction;
        }
      >({
        query: ({ organizationId, limit, startAfter, orderBy, direction }) => ({
          url: `${baseUrl}/organizations/${organizationId}/integrationCredentials/documents`,
          method: 'POST',
          body: {
            query: {
              limit,
              startAfter,
              orderBy,
              direction,
            },
          },
        }),
      }),
      saveIntegration: builder.mutation<
        void,
        { _id: string; fields: Record<string, string>; organizationId: string }
      >({
        query: ({ _id, fields, organizationId }) => ({
          url: `${baseUrl}/organizations/${organizationId}/integrations/document`,
          method: 'POST',
          body: { method: 'set', document: { _id, value: { ...fields } } },
        }),
      }),
      tasksHandler: builder.mutation<any, { organizationId: string } & TasksArgs[keyof TasksArgs]>({
        query: (args) => ({
          url: `${baseUrl}/organizations/${args.organizationId}/tasksHandler`,
          method: 'POST',
          body: omit(args, 'organizationId'),
        }),
        invalidatesTags: (result, error, queryArgs): Tags[] | [] => {
          const defaultTags: Tags[] = ['logs'];
          switch (queryArgs.event) {
            case 'createTemplate':
            case 'editTemplate':
              return [...defaultTags, 'templates'];
            case 'addAdminUser':
              return [...defaultTags, 'users'];
            case 'entitySetDocument':
              switch (queryArgs.args.entity) {
                case 'projects':
                case 'apps':
                case 'profiles':
                case 'segments':
                case 'locations':
                case 'products':
                case 'journeys':
                  return [...defaultTags, queryArgs.args.entity];
                default:
                  return defaultTags;
              }
            case 'callAction':
              switch (queryArgs.args.name) {
                case 'updateAutomatedMessage':
                case 'createAutomatedPush':
                case 'deleteAutomatedMessage':
                case 'createAbandonedCartPush':
                case 'updateAbandonedCartPush':
                case 'createInAppMessage':
                case 'updateInAppMessage':
                  return [...defaultTags, 'automatedMessages'];
                case 'deleteJourney':
                  return [...defaultTags, 'journeys'];
                default:
                  return defaultTags;
              }
            default:
              return defaultTags;
          }
        },
      }),
      getPublicProducts: builder.mutation<{ data: StoreProduct[] }, { organizationId: string }>({
        query: ({ organizationId }) => ({
          url: `${baseUrl}/organizations/${organizationId}/caches/documents/public/products`,
          method: 'Post',
          body: { query: {} },
        }),
      }),
      createOrder: builder.mutation<
        { result: 'success' | 'error'; error?: string; _id: string }[],
        Omit<Order, 'location' | 'status' | '_id'> & { organizationId: string; storeId: string }
      >({
        query: ({ organizationId, storeId, ...order }) => {
          return {
            url: `/orders/${organizationId}`,
            method: 'Post',
            body: [
              {
                method: 'create',
                document: {
                  ...order,
                  status: 'pending',
                  location: storeId,
                },
              },
            ],
          };
        },
      }),
      tasksHandlerGet: builder.query<any, { organizationId: string } & TasksArgs[keyof TasksArgs]>({
        query: ({ args, organizationId, event }) => ({
          url: `${baseUrl}/organizations/${organizationId}/tasksHandler`,
          method: 'POST',
          body: { event, args },
        }),
        providesTags: (result, error, queryArgs): Tags[] | [] => {
          switch (queryArgs.event) {
            case 'deleteSegment':
              return ['segments'];
            case 'profilesResolvers':
              return ['profiles'];
            case 'entityGetDocument':
            case 'entityGetDocuments':
              switch (queryArgs.args.entity) {
                case 'profiles':
                case 'segments':
                case 'locations':
                case 'products':
                case 'projects':
                case 'apps':
                case 'automatedMessages':
                case 'journeys':
                case 'users':
                case 'templates':
                  return [queryArgs.args.entity];
                default:
                  return [];
              }
            default:
              return [];
          }
        },
      }),
    }),
  });
