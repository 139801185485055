import {
  UseEditIntegrationFields,
  useEditIntegration,
  useIntegrations,
} from '@cannabis/account-portal-core/hooks/useIntegrations';
import { Integration, IntgerationIds } from '@cannabis/functions/entities/integrations';
import { CenterGrid } from '@tq1992/components/CenterGrid';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Loading } from '@tq1992/components/Loading';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

const getParams = ({ _id, value }: PartialIntegration): UseEditIntegrationFields => {
  switch (_id) {
    case 'sendgrid':
      return {
        _id,
        fields: [
          {
            _id: 'key',
            label: 'Key',
            value: value?.key,
          },
          {
            _id: 'domain',
            label: 'Domain',
            value: value?.domain,
          },
        ],
      };
    case 'twilio':
      return {
        _id,
        fields: [
          {
            _id: 'phoneNumber',
            label: 'Phone Number',
            value: value?.phoneNumber,
          },
          {
            _id: 'accountSid',
            label: 'Account Sid',
            value: value?.accountSid,
          },
          {
            _id: 'authToken',
            label: 'Auth Token',
            value: value?.authToken,
          },
        ],
      };
    case 'heart-jane':
      return {
        _id,
        fields: [
          {
            _id: 'apiKey',
            label: 'Api Key',
            value: value?.apiKey,
          },
          {
            _id: 'applicationId',
            label: 'ApplicationId',
            value: value?.applicationId,
          },
        ],
      };
  }
};

type PartialIntegration = Partial<Integration> & {
  _id: Integration['_id'];
  label: string;
};

const EditIntegrationField = ({ integration }: { integration: PartialIntegration }) => {
  const params = getParams(integration);
  const { submit, register } = useEditIntegration(params);
  const mappedFields = params.fields.map((field) => {
    return <TextField key={field._id} label={field.label} {...register(field._id)} />;
  });
  return (
    <FormContainer title={integration.label} onSubmit={submit} submitButtonLabel="Save Integration">
      {mappedFields}
    </FormContainer>
  );
};

const idLabels: { _id: IntgerationIds; label: string }[] = [
  { _id: 'sendgrid', label: 'Sendgrid' },
  { _id: 'twilio', label: 'Twilio' },
  { _id: 'heart-jane', label: 'Heart Jane' },
];

const IntegrationsList = () => {
  const { data: integrations, isLoading } = useIntegrations();
  const renderIntegrations = idLabels.map(({ _id, label }) => {
    const matchingIntegration = integrations?.find((integration) => integration._id === _id);
    return (
      <EditIntegrationField
        key={_id}
        integration={{ _id: _id as IntgerationIds, ...matchingIntegration, label }}
      />
    );
  });
  return (
    <Tabs options={idLabels?.map((integration) => integration.label) || []} appBar={true}>
      {isLoading ? (
        <CenterGrid>
          <Loading />
        </CenterGrid>
      ) : (
        renderIntegrations
      )}
    </Tabs>
  );
};

export const Integrations = () => <IntegrationsList />;
