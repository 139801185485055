import * as Yup from 'yup';

import { useSetDocumentHandlerEventFormFields } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Select, useSelect } from '@tq1992/components/Select';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

export const CreateProject = () => {
  const { data: tenants } = useTaskHandlerPagination('customerTenants', { orderBy: 'name' });

  const { options, onChange, value } = useSelect(
    [{ label: 'None', value: '' }].concat(
      tenants?.map((tenant) => ({ value: tenant.tenantId, label: tenant.name })) || [],
    ),
  );

  const { fields, register, submit } = useSetDocumentHandlerEventFormFields('projects', {
    fields: [{ label: 'Name', _id: 'name', schema: Yup.string().required() }],
    defaultArgs: {
      tenantId: value,
    },
  });
  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <Tabs options={['Create Project']} appBar={true}>
      <FormContainer title="Create Project" onSubmit={submit} submitButtonLabel="Save">
        {renderedFields}
        <Select options={options} onChange={onChange} value={value} label={'Tenant'} />
      </FormContainer>
    </Tabs>
  );
};
