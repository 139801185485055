import { getInAppMessageTemplateRoute } from '@cannabis/account-portal-core/AccountPortalAppConfigs';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { Table } from '@tq1992/components/Table';

import { ButtonLinkToPage } from '../../Navigation';

export const InAppMessageTemplates = () => {
  const { data, isLoading, pagination } = useTaskHandlerPagination('templates', {
    filters: [{ property: 'type', compare: '==', match: 'in-app-message' }],
  });
  return (
    <>
      <ButtonLinkToPage
        to="CreateInAppMessageTemplate"
        label="Create Template"
        iconify="ic:round-add-circle"
      />
      <Table
        pagination={pagination}
        rowKey="_id"
        headers={['Template Title', 'Title', 'Message', '_id']}
        mapRow={(data) => {
          if (data.type === 'in-app-message') {
            return [data.title, data.inAppTitle, data.body, data._id];
          }
          return [];
        }}
        isLoading={isLoading}
        data={data || []}
        mapRowLink={(data) => getInAppMessageTemplateRoute(data._id)}
      />
    </>
  );
};
