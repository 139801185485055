import { AccountPortalApp } from '@cannabis/account-portal-core';
import styled from 'styled-components';
import { template1Components } from '@cannabis/account-portal-core/templates/template1';

const Image = styled.img`
  width: 12rem;
  margin-right: 0.8rem;
`;

const App = template1Components({ logo: <Image src="../../logo.svg" /> });

const env = import.meta.env.VITE_APP_APP_CONFIG_ENV;

export default () => <AccountPortalApp env={env} App={App} />;
