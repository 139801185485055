import * as Yup from 'yup';

import { appApi } from '@cannabis/core/src/api/accountPortalApi';
import { CustomFormParams, useForm } from '@cannabis/core/src/hooks/useEditForm';
import { Integration } from '@cannabis/functions/entities/integrations';

import { useAppOrganizationContext } from '../contexts/AppOrganization';
import { useAccountPortalSnackbar } from './useAccountPortalSnackbar';
import { useTaskHandlerPagination } from './useTaskHandlerPagination';

export const useIntegrations = () => {
  return useTaskHandlerPagination('integrations');
};

export type UseEditIntegrationFields =
  | {
      _id: 'sendgrid';
      fields: CustomFormParams<{ _id: 'key'; value?: string } | { _id: 'domain'; value?: string }>;
    }
  | {
      _id: 'twilio';
      fields: CustomFormParams<
        | { _id: 'accountSid'; value?: string }
        | { _id: 'authToken'; value?: string }
        | { _id: 'phoneNumber'; value?: string }
      >;
    }
  | {
      _id: 'heart-jane';
      fields: CustomFormParams<
        { _id: 'apiKey'; value?: string } | { _id: 'applicationId'; value?: string }
      >;
    };

export const useEditIntegration = ({
  fields: fieldsArg,
  _id,
}: { _id: Integration['_id'] } & UseEditIntegrationFields) => {
  const { enqueueSnackbar } = useAccountPortalSnackbar();
  const { organization } = useAppOrganizationContext();
  return useForm({
    fields: fieldsArg.map((fieldArg) => ({ ...fieldArg, schema: Yup.string() })),
    mutation: appApi.endpoints.saveIntegration.useMutation,
    additionalArgs: { organizationId: organization._id, _id },
    onSuccess: () => enqueueSnackbar('Updated'),
    resetOnSuccess: false,
  });
};
