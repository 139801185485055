import { getJourneyRoute } from '@cannabis/account-portal-core/AccountPortalAppConfigs';
import { useAppOrganizationContext } from '@cannabis/account-portal-core/contexts/AppOrganization';
import { useAccountPortalSnackbar } from '@cannabis/account-portal-core/hooks/useAccountPortalSnackbar';
import { useJourneys } from '@cannabis/account-portal-core/hooks/useJourneys';
import { appApi } from '@cannabis/core/src/api/accountPortalApi';
import { getShortDateWithSeconds } from '@cannabis/core/src/dateUtils';
import { IconButton } from '@tq1992/components/IconButton';
import { Table } from '@tq1992/components/Table';

export const JourneysTable = () => {
  const { data, pagination } = useJourneys();
  const { enqueueSnackbar } = useAccountPortalSnackbar();

  const [mutate] = appApi.endpoints.tasksHandler.useMutation();
  const { organization } = useAppOrganizationContext();

  const onDelete = async (_id: string) => {
    await mutate({
      organizationId: organization._id,
      event: 'callAction',
      args: { name: 'deleteJourney', args: { _id } },
    });
    enqueueSnackbar('Message Deleted');
  };

  return (
    <Table
      headers={['Name', 'Segments', 'Delay', 'Last Updated', '']}
      data={data || []}
      mapRow={(journey) => [
        journey.name,
        journey.segments,
        `${journey.messageArgs.delay} Seconds`,
        getShortDateWithSeconds(journey.lastUpdated),
        <IconButton
          onClick={() => onDelete(journey._id)}
          tooltip="Delete"
          icon="ic:round-delete-sweep"
        />,
      ]}
      rowKey="_id"
      pagination={pagination}
      mapRowLink={(row) => getJourneyRoute(row._id)}
    />
  );
};
