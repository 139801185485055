import { createSlice } from '@tquinlan1992/react-redux-toolkit/dist/src';

export interface State {
  input1: string;
  input2: string;
}

const initialState: State = {
  input1: '',
  input2: '',
};

export const exampleSlice = createSlice({
  name: 'exampleSlice',
  initialState,
  reducers: {
    tom: (state) => {
      return state;
    },
  },
});
