import { createAppContext } from '@cannabis/core/src/createAppContext';
import { signOut } from '@tq1992/sso-client';

export const usePageContainer = (): {
  signOut: () => void;
} => {
  return {
    signOut,
  };
};

export const {
  useContext: usePageContainerContext,
  ContextProvider: PageContainerContextProvider,
} = createAppContext(usePageContainer);
