import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { getShortDateWithSeconds } from '@cannabis/core/src/dateUtils';
import { JsonViewer } from '@tq1992/components/JsonViewer';
import { Table } from '@tq1992/components/Table';

export const MessageHistory = () => {
  const { data, pagination, isLoading } = useTaskHandlerPagination('messages', {
    orderBy: 'created',
    direction: 'desc',
    pagination: {
      limit: 10,
    },
  });
  return (
    <Table
      isLoading={isLoading}
      headers={['_id', 'Event', 'Scheduled Date', 'Status', 'args', 'Created']}
      data={data || []}
      mapRow={({ _id, event, date, status, args, created }) => [
        _id,
        event,
        date ? getShortDateWithSeconds(date) : '',
        status,
        <JsonViewer collapsed={true} src={args} />,
        getShortDateWithSeconds(created),
      ]}
      rowKey="_id"
      pagination={pagination}
    />
  );
};
