import { useTaskHandlerPagination } from './useTaskHandlerPagination';

export const useUsers = ({
  orderBy = 'email',
  direction = 'desc',
}: {
  orderBy: string;
  direction: 'asc' | 'desc';
}) =>
  useTaskHandlerPagination('users', {
    filters: [{ property: 'type', compare: '==', match: 'customer' }],
    orderBy,
    direction,
  });
