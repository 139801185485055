import { useState } from 'react';

import {
  EntityKey,
  EntityModels,
  HandlerEvents,
  TaskHandlers,
} from '@cannabis/functions/express/tasksRouter';

import { initializedAppApi } from '../initializedAppApi';

export const useGetTaskEntityHandler = <Entity extends EntityKey>(
  { entity, organizationId }: { entity: Entity; organizationId: string },
  queries?: Omit<
    Parameters<TaskHandlers['entityGetDocument']>[0],
    'entity' | 'organizationId'
  >['args']['queries'],
  options?: { skip?: boolean },
) => {
  const result = initializedAppApi.appApi.endpoints.tasksHandlerGet.useQuery(
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      args: { entity, queries } as any,
      event: 'entityGetDocument',
      organizationId,
    },
    options,
  );

  return {
    data: (result.data?.data || null) as Awaited<
      ReturnType<EntityModels[Entity]['getDocument']>
    >['data'],
    isLoading: result.isLoading,
  };
};

export const useGetTaskEntityDistinct = <Entity extends EntityKey>({
  entity,
  organizationId,
  key,
}: {
  entity: Entity;
  organizationId: string;
  key: string;
}) => {
  const result = initializedAppApi.appApi.endpoints.tasksHandlerGet.useQuery({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args: { entity, key } as any,
    event: 'entityDistinct',
    organizationId,
  });

  return {
    data: (result.data?.data || null) as Awaited<
      ReturnType<EntityModels[Entity]['getDocument']>
    >['data'],
    isLoading: result.isLoading,
  };
};

export const useGetTaskHandlerEvent = <Event extends keyof HandlerEvents>({
  event,
  organizationId,
  args,
}: {
  event: Event;
  organizationId: string;
  args: Parameters<HandlerEvents[typeof event]>[0]['args'];
}) => {
  const [hasRefetched, setIsRefetching] = useState(false);
  const result = initializedAppApi.appApi.endpoints.tasksHandlerGet.useQuery({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args: args as any,
    event: event,
    organizationId,
  });

  const refetch = () => {
    setIsRefetching(true);
    result.refetch();
  };

  return {
    data: (result.data || null) as Awaited<ReturnType<HandlerEvents[typeof event]>>,
    isLoading: result.isLoading || result.isFetching,
    refetch,
    isRefetching: hasRefetched && result.isLoading,
  };
};

export const initiateGetTaskEntityHandler = <Entity extends EntityKey>(
  { entity, organizationId }: { entity: Entity; organizationId: string },
  queries?: Omit<
    Parameters<TaskHandlers['entityGetDocument']>[0],
    'entity' | 'organizationId'
  >['args']['queries'],
) => {
  return initializedAppApi.appApi.endpoints.tasksHandlerGet.initiate({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args: { entity, queries } as any,
    event: 'entityGetDocument',
    organizationId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  });
};
