import { useSegmentsPagination } from '@cannabis/account-portal-core/hooks/useSegments';
import { Table } from '@tq1992/components/Table';

export const SegmentsSelectTable = ({
  data: profiles,
  pagination,
  selectRow,
  isLoading,
}: ReturnType<typeof useSegmentsPagination>) => {
  if (!profiles) {
    return null;
  } else {
    return (
      <>
        {selectRow.selected.map((id) => (
          <p>{id}</p>
        ))}
        <Table
          isLoading={isLoading}
          pagination={pagination}
          headers={['Name', 'Filters', 'Last Updated', 'Created', '_id']}
          data={profiles}
          mapRow={({ name, filters, lastUpdated, created, _id }) => [
            name,
            JSON.stringify(filters),
            new Date(lastUpdated || '').toString(),
            new Date(created || '').toString(),
            _id,
          ]}
          rowKey="_id"
          selectRowProps={selectRow}
        />
      </>
    );
  }
};
