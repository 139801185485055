import * as Yup from 'yup';

import { useWebNavigatorContext } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { useAdminUsers } from '@cannabis/account-portal-core/hooks/useAdminUsers';
import { useMutationFormTasksHandlerEvent } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Table } from '@tq1992/components/Table';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

import { ButtonLinkToPage } from './Navigation';

export const CreateAdminUser = () => {
  const navigate = useWebNavigatorContext();
  const { fields, register, submit } = useMutationFormTasksHandlerEvent(
    'addAdminUser',
    [
      { label: 'Email', _id: 'email', schema: Yup.string().required() },
      { label: 'Password', _id: 'password', schema: Yup.string().required() },
    ],
    {
      successMessage: (params) => {
        navigate.AdminUsers();
        return `User "${params?.email}" Created`;
      },
    },
  );
  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <Tabs options={['Create Admin User']} appBar={true}>
      <>
        <FormContainer onSubmit={submit} submitButtonLabel="Create User" title="Create Admin User">
          {renderedFields}
        </FormContainer>
      </>
    </Tabs>
  );
};

export const AdminUsers = () => {
  const { data, pagination, isLoading } = useAdminUsers();
  return (
    <Tabs options={['Admin Users']} appBar={true}>
      <>
        <ButtonLinkToPage to="CreateAdminUser" label="Create Admin User" />
        <Table
          isLoading={isLoading}
          headers={['Email', 'Role', '_id']}
          data={data?.map((user) => ({ ...user, _id: user._id })) || []}
          mapRow={({ email, role, _id }) => [email, role, _id]}
          rowKey="_id"
          pagination={pagination}
        />
      </>
    </Tabs>
  );
};
