/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

// @mui
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeOptions } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';

import GlobalStyles from './globalStyles';
import palette from './palette';
import typography from './typography';

export const themeOptions: ThemeOptions = {
  palette,
  shape: { borderRadius: 6 },
  typography: typography as any,
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'black',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '14px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '6px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px',
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);
export const ThemeProvider = ({ children }: any) => {
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};
