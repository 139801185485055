import { manageUserSession } from '@cannabis/core/src';

import { AccountPortalEnvConfigs } from './AccountPortalAppConfigs';

let signIn: ReturnType<typeof manageUserSession>['signIn'];
let isSignedIn: ReturnType<typeof manageUserSession>['isSignedIn'];
let signOut: ReturnType<typeof manageUserSession>['signOut'];

export const getAuthMethods = () => ({ signIn, isSignedIn, signOut });

export const initializeAccountPortalEnvConfigs = (params: AccountPortalEnvConfigs) => {
  const {
    signIn: signInReturn,
    isSignedIn: isSignedInReturn,
    signOut: signOutReturn,
  } = manageUserSession({
    firebaseJson: params.firebaseJson,
    baseUrl: params.baseUrl,
    localAuth: params.localAuth,
  });
  signIn = signInReturn;
  isSignedIn = isSignedInReturn;
  signOut = signOutReturn;
};
