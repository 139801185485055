import React from 'react';

import { Grid } from '@mui/material';

export const CenterGrid = ({ children }: { children: React.ReactNode }) => (
  <Grid
    marginTop={'24px'}
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <Grid item>{children}</Grid>
  </Grid>
);
