import * as Yup from 'yup';

import { useSetDocumentHandlerEventFormFields } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useLocations } from '@cannabis/account-portal-core/hooks/useLocations';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Table } from '@tq1992/components/Table';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

import { ButtonLinkToPage } from './Navigation';

export const CreateLocation = () => {
  const { fields, register, submit } = useSetDocumentHandlerEventFormFields('locations', {
    fields: [{ label: 'Title', _id: 'title', schema: Yup.string().required() }],
    successMessage: (params) => `Location "${params?.title}" Created`,
  });
  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <Tabs options={['Create Location']} appBar={true}>
      <FormContainer title="Create Location" onSubmit={submit} submitButtonLabel="Create Location">
        {renderedFields}
      </FormContainer>
    </Tabs>
  );
};

export const Locations = () => {
  const { locations, pagination, isLoading } = useLocations();
  return (
    <Tabs options={['Locations']} appBar={true}>
      <>
        <ButtonLinkToPage
          to="CreateLocation"
          label="Create Location"
          iconify="ic:round-add-business"
        />
        <Table
          isLoading={isLoading}
          headers={['Title', 'Last Updated', '_id']}
          data={locations}
          mapRow={({ title, lastUpdated, _id }) => [
            title,
            lastUpdated ? new Date(lastUpdated).toString() : '',
            _id,
          ]}
          rowKey="_id"
          pagination={pagination}
        />
      </>
    </Tabs>
  );
};
