import { initializeAppApi } from '@cannabis/core/src/api/accountPortalApi';
import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit';

import { reducer } from './actions';

export const getStore = (baseUrl: string) => {
  const appApi = initializeAppApi(baseUrl);

  const apisReducer = {
    [appApi.reducerPath]: appApi.reducer,
  };

  const store = rtkConfigureStore({
    reducer: { ...apisReducer, ...reducer },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(appApi.middleware),
  });

  return store;
};

export type RootState = ReturnType<typeof getStore>['getState'];
