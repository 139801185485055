/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityModels, HandlerEvents } from '@cannabis/functions/express/tasksRouter';

import { initializedAppApi } from '../initializedAppApi';

type EntitySetDocumentParam<EntityKey extends keyof EntityModels> = Parameters<
  EntityModels[EntityKey]['setDocument']
>[0]['mutation']['document'];

export const useSetMutationHandlerEvent = <EntityKey extends keyof EntityModels>(
  entity: EntityKey,
  organizationId: string,
) => {
  const [mutationSave, mutationArgs] =
    initializedAppApi.appApi.endpoints.tasksHandler.useMutation();
  const save = (document: EntitySetDocumentParam<EntityKey>) => {
    mutationSave({
      event: 'entitySetDocument',
      organizationId,
      args: {
        entity,
        document,
        method: 'set',
      },
    });
  };
  return { save, mutationArgs };
};

export const useMutationHandlerEvent = <Event extends keyof HandlerEvents>(
  event: Event,
  organizationId: string,
) => {
  const [mutate, mutateArgs] = initializedAppApi.appApi.endpoints.tasksHandler.useMutation();
  return {
    mutate: (args: Parameters<HandlerEvents[typeof event]>[0]['args']) =>
      mutate({
        event,
        args: args as any,
        organizationId,
      } as any) as any,
    mutateArgs: {
      data: mutateArgs.data as Awaited<ReturnType<HandlerEvents[typeof event]>> | null,
      // data: mutateArgs.data as Awaited<ReturnType<TaskHandlers[typeof event]>> | null,
      isLoading: mutateArgs.isLoading,
      error: mutateArgs.error,
    },
  };
};
