import * as Yup from 'yup';

import { useMutationFormTasksHandlerEvent } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useSegmentsPagination } from '@cannabis/account-portal-core/hooks/useSegments';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { DateTimePicker } from '@tq1992/components/DateTimePicker';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Select, useSelect } from '@tq1992/components/Select';
import { TextField } from '@tq1992/components/TextField';

import { SegmentsSelectTable } from '../SegmentsSelectTable';

export const SendToApp = () => {
  const props = useSegmentsPagination();
  const { data: apps } = useTaskHandlerPagination('apps', { orderBy: 'name' });

  const { options, onChange, value } = useSelect(
    [{ label: 'None', value: '' }].concat(
      apps?.map((app) => ({ value: app._id, label: app.name })) || [],
    ),
  );

  const { fields, register, submit, setValue } = useMutationFormTasksHandlerEvent(
    'sendNotificationsToApp',
    [
      { label: 'Title', _id: 'title', schema: Yup.string() },
      { label: 'Body', _id: 'body', schema: Yup.string().required() },
      { label: 'Date', _id: 'date', schema: Yup.string() },
    ],
    {
      appId: value,
      segments: props.selectRow.selected,
    },
  );
  const renderedFields = fields.map((field) => {
    switch (field._id) {
      case 'date':
        return <DateTimePicker key={field._id} onChange={(value) => setValue(field._id, value)} />;
      default:
        return <TextField key={field._id} label={field.label} {...register(field._id)} />;
    }
  });
  return (
    <>
      <FormContainer title="Send to App" onSubmit={submit} submitButtonLabel="Send">
        {renderedFields}
        <Select options={options} onChange={onChange} value={value} label={'App'} />
      </FormContainer>
      <SegmentsSelectTable {...props} />
    </>
  );
};
