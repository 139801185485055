import { useState } from 'react';

import { EntityResolvers, TaskHandlers } from '@cannabis/functions/express/tasksRouter';
import { CompareOperators } from '@tq1992/components/Table/Filters';
import { usePagination } from '@tq1992/components/Table/usePagination';

import { initializedAppApi } from '../initializedAppApi';

type Params<Resolver> = {
  resolver: Resolver;
  organizationId: string;
  queries?: Omit<
    Parameters<TaskHandlers['entityGetDocuments']>[0],
    'entity' | 'organizationId'
  >['args']['queries'];
  pagination?: Omit<
    Parameters<TaskHandlers['entityGetDocuments']>[0],
    'entity' | 'organizationId'
  >['args']['pagination'];
  orderBy?: Omit<
    Parameters<TaskHandlers['entityGetDocuments']>[0],
    'entity' | 'organizationId'
  >['args']['orderBy'];
  direction?: Omit<
    Parameters<TaskHandlers['entityGetDocuments']>[0],
    'entity' | 'organizationId'
  >['args']['direction'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mongoQuery?: any;
};

export const useGetProjectsResolversHandler = <Resolver extends keyof EntityResolvers['projects']>({
  resolver,
  organizationId,
  queries,
  pagination,
}: Params<Resolver>) => {
  const result = initializedAppApi.appApi.endpoints.tasksHandlerGet.useQuery({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args: { queries, resolver, pagination } as any,
    event: 'projectsResolvers',
    organizationId,
  });

  return {
    data: (result.data?.data || null) as Awaited<
      Awaited<ReturnType<EntityResolvers['projects'][Resolver]>>['data']
    >,
    isLoading: result.isLoading,
  };
};

export const useGetProfileResolversHandler = <Resolver extends keyof EntityResolvers['profiles']>({
  resolver,
  organizationId,
  queries,
  pagination,
  orderBy,
  direction,
  mongoQuery,
}: Params<Resolver>) => {
  const [filters, setFilters] = useState<
    { property: string; compare: CompareOperators; match: string }[]
  >((queries as { property: string; compare: CompareOperators; match: string }[]) || []);
  const result = usePagination({
    organizationId,
    orderBy,
    direction,
    pagination,
    useQuery: (paginationArgs) => {
      return initializedAppApi.appApi.endpoints.tasksHandlerGet.useQuery({
        args: {
          queries: filters,
          orderBy: paginationArgs.orderBy,
          direction: paginationArgs.direction,
          pagination: paginationArgs.pagination,
          resolver,
          mongoQuery,
        },
        organizationId,
        event: 'profilesResolvers',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    },
  });
  return {
    ...result,
    filters,
    setFilters,
    data: result.data as Awaited<
      Awaited<ReturnType<EntityResolvers['profiles'][Resolver]>>['data']
    >,
  };
};
