import { map } from 'lodash';
import { useState } from 'react';
import * as Yup from 'yup';

import { getProfileRoute } from '@cannabis/account-portal-core/AccountPortalAppConfigs';
import { useSetDocumentHandlerEventFormFields } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useProfiles } from '@cannabis/account-portal-core/hooks/useProfiles';
import { getShortDateWithSeconds } from '@cannabis/core/src/dateUtils';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Table } from '@tq1992/components/Table';
import { FiltersResult } from '@tq1992/components/Table/Filters';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

const CreateSegmentForm = ({
  filters,
  onSuccess,
}: {
  filters: FiltersResult<string>;
  onSuccess: () => void;
}) => {
  const { register, submit, fields } = useSetDocumentHandlerEventFormFields('segments', {
    fields: [{ label: 'Name', _id: 'name', schema: Yup.string().required() }],
    defaultArgs: {
      filters,
    },
    defaultArgsSchema: Yup.object({
      filters: Yup.array().min(1),
    }),
    successMessage: (params) => `Segment "${params?.name}" Created`,
    onSuccess,
  });

  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <FormContainer title="Create Segment" onSubmit={submit} submitButtonLabel="Save Segment">
      {renderedFields}
    </FormContainer>
  );
};

export const CreateSegment = () => {
  const {
    profilesPaginationProps: { data: profiles, pagination, setFilters, filters },
  } = useProfiles();
  const [key, setKey] = useState(1);
  const resetFilters = () => {
    setKey(key + 1);
  };
  if (!profiles) {
    return null;
  } else {
    return (
      <Tabs options={['Create Segment']} appBar={true}>
        <>
          <CreateSegmentForm filters={filters} onSuccess={resetFilters} />
          <Table
            key={key}
            filters={{
              onAdd: (newFilters) =>
                setFilters(
                  newFilters.map(({ property, compare, match }) => ({
                    property: String(property),
                    compare,
                    match,
                  })),
                ),
              keyLabel: 'Key',
              valueLabel: 'Value',
              option: {
                keyOptions: [
                  { label: 'Country', value: 'country' },
                  { label: 'Segment', value: 'segment' },
                ],
                valueOptions: {
                  country: {
                    options: [
                      { label: 'USA', value: 'USA' },
                      { label: 'Mexico', value: 'Mexico' },
                    ],
                    operators: ['=='],
                  },
                  segment: {
                    options: [
                      {
                        label: 'USA Users',
                        value: 'segment.199e1384-13a0-4f9c-bf1f-aac6cb88d4ac.active',
                      },
                    ],
                    operators: ['=='],
                  },
                },
              },
            }}
            pagination={pagination}
            headers={['Email', 'Phone Number', 'Country', 'Segments', 'Last Updated', 'Created']}
            data={profiles}
            mapRow={({ email, phoneNumber, country, lastUpdated, created, segments }) => [
              email,
              phoneNumber,
              country,
              map(segments, (segment) => segment).filter((segment) => segment.active).length,
              getShortDateWithSeconds(lastUpdated),
              getShortDateWithSeconds(created),
            ]}
            rowKey="_id"
            mapRowLink={(row) => getProfileRoute(row._id)}
          />
        </>
      </Tabs>
    );
  }
};
