import { useLogs } from '@cannabis/account-portal-core/hooks/useLogs';
import { getShortDateWithSeconds } from '@cannabis/core/src/dateUtils';
import { JsonViewer } from '@tq1992/components/JsonViewer';
import { Table, useSortTable } from '@tq1992/components/Table';
import { Tabs } from '@tq1992/components/Tabs';

const getTimeOptionsTime = (
  timeOptions?: { type: 'schedule'; date: string } | { type: 'delay'; seconds: number },
) => {
  switch (timeOptions?.type) {
    case 'schedule':
      return `scheduled ${getShortDateWithSeconds(timeOptions.date)}`;
    case 'delay':
      return `${timeOptions.seconds} seconds delay`;
    default:
      return '';
  }
};

export const Logs = () => {
  const { activeSort, sortDirection, onClickSort } = useSortTable({
    initalDirection: 'desc',
    initialSort: 'lastUpdated',
  });
  const { logs, pagination, setFilters, isLoading } = useLogs({
    orderBy: activeSort,
    direction: sortDirection,
  });
  return (
    <Tabs options={['Logs']} appBar={true}>
      <>
        <Table
          {...{ activeSort, sortDirection, onClickSort }}
          isLoading={isLoading}
          filters={{
            onAdd: (newFilters) =>
              setFilters(
                newFilters.map(({ property, compare, match }) => ({
                  property: String(property),
                  compare,
                  match,
                })),
              ),
            keyLabel: 'Key',
            valueLabel: 'Value',
            option: {
              keyOptions: [{ label: 'Type', value: 'type' }],
              valueOptions: {
                type: {
                  options: [
                    { label: 'Request', value: 'REQUEST' },
                    { label: 'Task', value: 'TASK' },
                  ],
                  operators: ['=='],
                },
              },
            },
          }}
          headers={[
            '_id',
            'Parent Task',
            'Tags',
            'Event',
            'Status',
            'Type',
            'Entity/Method',
            'Args',
            'Time Options',
            { label: 'Last Updated', value: 'lastUpdated', sort: true },
            { label: 'Created', value: 'created', sort: true },
          ]}
          data={logs}
          mapRow={({
            _id,
            parentTaskId,
            tags,
            event,
            lastUpdated,
            created,
            args,
            status,
            timeOptions,
            type,
          }) => [
            _id,
            parentTaskId,
            tags,
            event,
            status,
            type,
            args.entity ? `${args.entity}-${args.method ? args.method : 'get'}` : '',
            <JsonViewer collapsed={true} src={args} />,
            getTimeOptionsTime(timeOptions),
            getShortDateWithSeconds(lastUpdated),
            getShortDateWithSeconds(created),
          ]}
          rowKey="_id"
          pagination={pagination}
        />
      </>
    </Tabs>
  );
};
