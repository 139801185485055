import { useParams } from 'react-router-dom';

import { useApp } from '@cannabis/account-portal-core/hooks/useApp';
import { Typography } from '@mui/material';
import { CenterGrid } from '@tq1992/components/CenterGrid';
import { Loading } from '@tq1992/components/Loading';
import { Tabs } from '@tq1992/components/Tabs';

export const App = () => {
  const { _id } = useParams();
  const { data, isLoading } = useApp(_id as string);

  return (
    <Tabs options={[`App: ${data?.name}`]} appBar={true}>
      {isLoading ? (
        <CenterGrid>
          <Loading />
        </CenterGrid>
      ) : (
        <Typography>{data?._id}</Typography>
      )}
    </Tabs>
  );
};
