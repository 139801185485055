// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useAppOrganizationContext } from '@cannabis/account-portal-core/contexts/AppOrganization';
import { useWebNavigatorContext } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { useAccountPortalSnackbar } from '@cannabis/account-portal-core/hooks/useAccountPortalSnackbar';
import { useGetTaskEntityHandler } from '@cannabis/account-portal-core/hooks/useGetTaskEntityHandler';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { appApi } from '@cannabis/core/src/api/accountPortalApi';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Loading } from '@tq1992/components/Loading';
import { Select, useSelect } from '@tq1992/components/Select';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';
import { useReactFormToolkit } from '@tquinlan1992/react-redux-toolkit';

export const CreateOnboardingTemplate = ({ _id }: { _id?: string }) => {
  const navigate = useWebNavigatorContext();

  const { data } = useGetTaskEntityHandler(
    'templates',
    [
      {
        property: '_id',
        compare: '==',
        match: _id as string,
      },
    ],
    { skip: !_id },
  );

  const { data: emailTemplates } = useTaskHandlerPagination('templates', {
    filters: [{ property: 'type', compare: '==', match: 'email' }],
  });

  const {
    options: emailTemplateOptions,
    onChange: onChangeEmailTemplate,
    value: emailTemplateValue,
  } = useSelect(
    [
      { value: '', label: 'None' },
      ...(emailTemplates?.map((template) => ({ value: template._id, label: template.title })) ||
        []),
    ],
    data?.type === 'onboarding' ? data.verifyEmailTemplate : '',
  );

  const { data: smsTemplates } = useTaskHandlerPagination('templates', {
    filters: [{ property: 'type', compare: '==', match: 'sms' }],
  });

  const {
    options: smsTemplateOptions,
    onChange: onChangeSmsTemplate,
    value: smsTemplateValue,
  } = useSelect(
    [
      { value: '', label: 'None' },
      ...(smsTemplates?.map((template) => ({ value: template._id, label: template.title })) || []),
    ],
    data?.type === 'onboarding' ? data.verifySmsTemplate : '',
  );

  const {
    options: welcomeEmailTemplateOptions,
    onChange: onChangeWelcomeEmailTemplate,
    value: welcomeEmailTemplateValue,
  } = useSelect(
    [
      { value: '', label: 'None' },
      ...(emailTemplates?.map((template) => ({ value: template._id, label: template.title })) ||
        []),
    ],
    data?.type === 'onboarding' ? data.welcomeEmailTemplate : '',
  );

  const [mutate] = appApi.endpoints.tasksHandler.useMutation();
  const { organization } = useAppOrganizationContext();
  const { enqueueSnackbar } = useAccountPortalSnackbar();

  const { register, handleSubmit, getValues } = useReactFormToolkit({
    schema: Yup.object({
      title: Yup.string(),
    }),
    defaultValues: {
      title: data?.title,
    },
  });

  const event = data ? 'editTemplate' : 'createTemplate';

  return (
    <Tabs
      options={[data ? 'Edit Onboarding Template' : 'Create Onboarding Templates']}
      appBar={true}
      link={true}
    >
      <FormContainer
        title="Create Template"
        onSubmit={handleSubmit(async () => {
          await mutate({
            organizationId: organization._id,
            event,
            args: {
              type: 'onboarding',
              verifyEmailTemplate: emailTemplateValue,
              verifySmsTemplate: smsTemplateValue,
              title: getValues().title || '',
              _id: data?._id || '',
              welcomeEmailTemplate: welcomeEmailTemplateValue,
            },
          });
          enqueueSnackbar(data ? 'Template Updated' : 'Template Created');
          navigate.Onboarding();
        })}
        submitButtonLabel={data ? 'Update Onboarding Template' : 'Create Onboarding Template'}
      >
        <TextField {...register('title')} label="Title" />
        <Select
          options={emailTemplateOptions}
          onChange={onChangeEmailTemplate}
          value={emailTemplateValue}
          label={'Email Verify Template'}
        />
        <Select
          options={smsTemplateOptions}
          onChange={onChangeSmsTemplate}
          value={smsTemplateValue}
          label={'Phone Number Verify Template'}
        />
        <Select
          options={welcomeEmailTemplateOptions}
          onChange={onChangeWelcomeEmailTemplate}
          value={welcomeEmailTemplateValue}
          label={'Welcome Email Template'}
        />
      </FormContainer>
    </Tabs>
  );
};

export const EditOnboardingTemplate = () => {
  const { _id } = useParams();
  const { isLoading } = useGetTaskEntityHandler('templates', [
    {
      property: '_id',
      compare: '==',
      match: _id as string,
    },
  ]);

  return isLoading ? <Loading /> : <CreateOnboardingTemplate _id={_id} />;
};
