import {
  Fields,
  useMutationFormTasksHandlerEvent,
} from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

import { SendSmsToSegment } from './SendToSegmentSms';
import { SmsTemplates } from './SmsTemplates';

const SendSmsToOne = () => {
  const fields: Fields<{ label: string }>['sendSms'] = [
    { label: 'To', _id: 'to' },
    { label: 'Message', _id: 'message' },
  ];
  const { register, submit } = useMutationFormTasksHandlerEvent('sendSms', fields);

  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <FormContainer title="Send Sms" onSubmit={submit} submitButtonLabel="Send Sms">
      {renderedFields}
    </FormContainer>
  );
};

export const SendSms = () => {
  return (
    <Tabs options={['Templates', 'Send To One User', 'Send To Segment']}>
      <SmsTemplates />
      <SendSmsToOne />
      <SendSmsToSegment />
    </Tabs>
  );
};
