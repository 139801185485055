import { forwardRef } from 'react';

import { Icon } from '@iconify/react';
import { Tooltip } from '@mui/material';
import { Box } from '@mui/material';
import MaterialUiIconButton from '@mui/material/IconButton';

// ----------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Iconify = forwardRef(({ icon, width = 20, sx, ...other }: any, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
));

export const IconButton = ({
  icon,
  tooltip,
  onClick,
}: {
  icon: string;
  tooltip: string;
  onClick: () => void;
}) => (
  <Tooltip title={tooltip}>
    <MaterialUiIconButton
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
      color="error"
    >
      <Iconify icon={icon} width={24} height={24} />
    </MaterialUiIconButton>
  </Tooltip>
);
