import Case from 'case';
import { omit } from 'lodash';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import * as Yup from 'yup';

import { appApi } from '@cannabis/core/src/api/accountPortalApi';
import { useForm } from '@cannabis/core/src/hooks/useEditForm';
import { CannabisActions } from '@cannabis/functions/actions/callAction';
import { EntityKey, EntityModels, TasksArgs } from '@cannabis/functions/express/tasksRouter';

import { useAppOrganizationContext } from '../contexts/AppOrganization';
import { useAccountPortalSnackbar } from './useAccountPortalSnackbar';

// eslint-disable-next-line @typescript-eslint/ban-types
export type Fields<AdditionalFields = {}> = {
  [P in keyof TasksArgs]: ({
    _id: keyof TasksArgs[P]['args'];
    value?: string | number;
    schema?: Yup.AnySchema;
    label: string;
  } & AdditionalFields)[];
};

export const useMutationFormTasksHandlerEvent = <FieldKey extends keyof Fields>(
  fieldKey: FieldKey,
  fields: Fields[FieldKey],
  additionalFieldsParams?: Record<string, unknown> & {
    successMessage?: (fields?: TasksArgs[FieldKey]['args']) => string;
    resetOnSuccess?: boolean;
  },
) => {
  const { successMessage = () => 'Success' } = additionalFieldsParams || {};
  const { organization } = useAppOrganizationContext();
  const { enqueueSnackbar } = useAccountPortalSnackbar();
  return useForm({
    onSuccess: (fields) => {
      enqueueSnackbar(Case.capital(successMessage(fields as any)));
    },
    fields: fields.map((fieldArg) => ({
      ...fieldArg,
      schema: fieldArg.schema || Yup.string().required(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    })) as any,
    mutation: appApi.endpoints.tasksHandler.useMutation,
    additionalArgs: { organizationId: organization._id, event: fieldKey },
    fieldsName: 'args',
    additionalFields: omit(additionalFieldsParams, 'resetOnSuccess'),
    resetOnSuccess: additionalFieldsParams?.resetOnSuccess,
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ActionFields<AdditionalFields = {}> = {
  [P in keyof CannabisActions]: ({
    _id: keyof Yup.InferType<CannabisActions[P]['schema']>;
    value?: string | number | (string | number)[];
    schema?: Yup.AnySchema;
    label: string;
  } & AdditionalFields)[];
};

export const useMutationFormCallAction = <ActionName extends keyof ActionFields>(
  fieldKey: ActionName,
  fieldsInfo: {
    fields: ActionFields[ActionName];
    initialValues?: Partial<Yup.InferType<CannabisActions[ActionName]['schema']>>;
  },
  additionalFieldsParams?: Partial<Yup.InferType<CannabisActions[ActionName]['schema']>> & {
    successMessage?: (fields?: CannabisActions[ActionName]['schema']) => string;
  },
) => {
  const { successMessage = () => 'Success', ...additionalFields } = additionalFieldsParams || {};
  const { organization } = useAppOrganizationContext();
  const { enqueueSnackbar } = useAccountPortalSnackbar();
  return useForm({
    onSuccess: (fields) => {
      enqueueSnackbar(Case.capital(successMessage(fields as any)));
    },
    initialValues: fieldsInfo.initialValues,
    fields: fieldsInfo.fields.map((fieldArg) => ({
      ...fieldArg,
      schema: fieldArg.schema || Yup.string().required(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    })) as any,
    mutation: appApi.endpoints.tasksHandler.useMutation,
    fieldsName: 'args',
    additionalFields: { ...additionalFields, name: fieldKey },
    mapFields: (fields) => ({
      event: 'callAction',
      organizationId: organization._id,
      args: {
        name: fieldKey,
        args: { ...fields, ...additionalFields },
      },
    }),
  });
};

type EntitySetDocumentParam<EntityKey extends keyof EntityModels> = Parameters<
  EntityModels[EntityKey]['setDocument']
>[0]['mutation']['document'];

export type EntityFields = {
  [P in keyof EntityModels]: {
    _id: keyof EntitySetDocumentParam<P>;
    value?: string | number;
    schema?: Yup.AnySchema;
    label: string;
  }[];
};

export const useSetDocumentHandlerEventFormFields = <Entity extends EntityKey>(
  entityKey: Entity,
  {
    defaultArgs,
    fields,
    defaultArgsSchema,
    initialValues,
    successMessage = () => 'Success',
    onSuccess = () => null,
    resetOnSuccess,
  }: {
    defaultArgs?: Partial<EntitySetDocumentParam<Entity>>;
    defaultArgsSchema?: Yup.AnySchema;
    fields: EntityFields[Entity];
    initialValues?: Partial<EntitySetDocumentParam<Entity>>;
    successMessage?: (fields?: EntitySetDocumentParam<Entity>) => string;
    onSuccess?: () => void;
    resetOnSuccess?: boolean;
  },
) => {
  const { enqueueSnackbar } = useAccountPortalSnackbar();
  const { organization } = useAppOrganizationContext();
  const [defaultArgsErrors, setDefaultArgsErrors] = useState<Record<string, any>>({});
  const form = useForm({
    resetOnSuccess,
    onSuccess: (values) => {
      onSuccess();
      enqueueSnackbar(Case.capital(successMessage(values as any)));
    },
    initialValues: initialValues,
    fields: fields.map((fieldArg) => ({
      ...fieldArg,
      schema: fieldArg.schema || Yup.string().required(),
    })) as any,
    mutation: appApi.endpoints.tasksHandler.useMutation,
    fieldsName: 'args',
    mapFields: (newFields) => {
      if (defaultArgsSchema) {
        try {
          defaultArgsSchema.validateSync(defaultArgs);
        } catch (e: any) {
          setDefaultArgsErrors(e);
          return;
        }
      }
      return {
        args: { document: { ...defaultArgs, ...newFields }, entity: entityKey, method: 'set' },
        organizationId: organization._id,
        event: 'entitySetDocument',
      };
    },
  });

  return {
    ...form,
    submit: form.submit,
    errors: { ...form.errors, ...defaultArgsErrors },
    fields,
  };
};

export const useSetMutationHandlerEvent = <EntityKey extends keyof EntityModels>(
  entity: EntityKey,
) => {
  const { organization } = useAppOrganizationContext();
  const [mutationSave, mutationArgs] = appApi.endpoints.tasksHandler.useMutation();
  const save = (document: Omit<EntitySetDocumentParam<EntityKey>, '_id'>) => {
    mutationSave({
      event: 'entitySetDocument',
      organizationId: organization._id,
      args: {
        entity,
        document,
        method: 'set',
      },
    });
  };
  return { save, mutationArgs };
};
