import { mapValues } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { createAppContext } from '@cannabis/core/src/createAppContext';

import { Page, navigationRoutes } from '../AccountPortalAppConfigs';

function swapKeysAndValues(obj: Record<string, string>): Record<string, string> {
  // 👇️ [['color', 'blue'], ['fruit', 'apple']]
  const swapped = Object.entries(obj).map(([key, value]) => [value, key]);

  return Object.fromEntries(swapped);
}

const pathsToPage = swapKeysAndValues(navigationRoutes);

export const useWebNavigate = (): Record<Page, () => void> & {
  currentlySelected: Page;
  pathname: string;
} => {
  const { pathname } = useLocation();
  const currentlySelectedPage = pathsToPage[pathname];
  const navigate = useNavigate();
  return {
    currentlySelected: currentlySelectedPage as Page,
    ...mapValues(navigationRoutes, (path) => () => navigate(path)),
    pathname,
  };
};

export const { useContext: useWebNavigatorContext, ContextProvider: WebNavigateContextProvider } =
  createAppContext(useWebNavigate);
