import { useTaskHandlerPagination } from './useTaskHandlerPagination';

export const useJourneys = () => {
  return useTaskHandlerPagination('journeys', {
    orderBy: 'lastUpdated',
    direction: 'desc',
    pagination: {
      limit: 10,
    },
  });
};
