import { SnackbarProvider } from 'notistack';

import { AccountPortalAppConfigs } from '@cannabis/account-portal-core/AccountPortalAppConfigs';
import { styled } from '@mui/material';
import { CenterGrid } from '@tq1992/components/CenterGrid';
import { Loading } from '@tq1992/components/Loading';
import { theme1 } from '@tq1992/components/colors';
import { ThemeProvider } from '@tq1992/components/minimal-main/theme';

import { Navigation } from './Navigation';

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    background-color: white;
    color: ${theme1.primary};
  }
`;

export const template1Components =
  ({ logo }: { logo?: JSX.Element }) =>
  ({ config, isLoadingConfigs }: Parameters<AccountPortalAppConfigs['App']>[0]) => {
    const body = isLoadingConfigs ? (
      <CenterGrid>
        <Loading />
      </CenterGrid>
    ) : (
      <StyledSnackbarProvider maxSnack={3}>
        <Navigation logo={logo} config={config} />
      </StyledSnackbarProvider>
    );
    return <ThemeProvider>{body}</ThemeProvider>;
  };
