import EmojiPicker from 'emoji-picker-react';
import React from 'react';
import * as Yup from 'yup';

import { useWebNavigate } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { useMutationFormCallAction } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { Grid } from '@mui/material';
import { Accordion } from '@tq1992/components/Accordion';
import { Container } from '@tq1992/components/Container';
import { DateTimePicker } from '@tq1992/components/DateTimePicker';
import { FormContainer } from '@tq1992/components/FormContainer';
import { ApplePushNotificationPreview } from '@tq1992/components/PushNotificationPreview';
import { Select, useSelect } from '@tq1992/components/Select';
import { TextField } from '@tq1992/components/TextField';

import { useAutomatedMessageFromId } from '../EditAutomatedMessage';

const getUseAutomatedMessageFromId = (_id?: string) => {
  if (_id) {
    return () => useAutomatedMessageFromId(_id);
  } else {
    return () => ({ data: null });
  }
};

export const CreateInAppMessage = ({ _id }: { _id?: string }) => {
  const { data: apps } = useTaskHandlerPagination('apps', { orderBy: 'name' });
  const { data: automatedMessage } = getUseAutomatedMessageFromId(_id)();
  const navigate = useWebNavigate();
  const {
    options: appSelectionOptions,
    onChange: onChangeApp,
    value: appSelectedValue,
  } = useSelect(
    [{ label: 'None', value: '' }].concat(
      apps?.map((app) => ({ value: app._id, label: app.name })) || [],
    ),
    automatedMessage?.type === 'IN_APP_MESSAGE' ? automatedMessage.appId : '',
  );
  const useEvent = _id ? 'updateInAppMessage' : 'createInAppMessage';
  const { fields, register, submit, setValue, getValues, watch } = useMutationFormCallAction(
    useEvent,
    {
      initialValues: automatedMessage?.type === 'IN_APP_MESSAGE' ? automatedMessage : {},
      fields: [
        {
          _id: 'title',
          label: 'Title',
          schema: Yup.string().optional(),
        },
        {
          _id: 'body',
          label: 'Body',
        },
        {
          _id: 'startTime',
          label: 'Start Time',
          schema: Yup.string().optional(),
        },
        {
          _id: 'endTime',
          label: 'End Time',
          schema: Yup.string().optional(),
        },
      ],
    },
    {
      appId: appSelectedValue,
      successMessage: () => {
        navigate.AutomatedMessages();
        return _id ? 'Message Updated' : 'Message Created';
      },
      type: 'IN_APP_MESSAGE',
    },
  );
  const renderedFields = React.useMemo(
    () =>
      fields.map((field) => {
        switch (field._id) {
          case 'startTime':
            return (
              <DateTimePicker
                type="time"
                key={field._id}
                onChange={(value) => setValue(field._id, value)}
                initialValue={
                  automatedMessage?.type === 'IN_APP_MESSAGE' ? automatedMessage.startTime : ''
                }
              />
            );
          case 'endTime':
            return (
              <DateTimePicker
                type="time"
                key={field._id}
                onChange={(value) => setValue(field._id, value)}
                initialValue={
                  automatedMessage?.type === 'IN_APP_MESSAGE' ? automatedMessage.endTime : ''
                }
              />
            );
          default:
            return (
              <React.Fragment key={field._id}>
                <TextField key={field._id} label={field.label} {...register(field._id)} />
                <Accordion header="Emoji Keyboard 🍃🔥💨">
                  <EmojiPicker
                    key={field._id}
                    onEmojiClick={(emoji) => {
                      setValue(field._id, `${getValues(field._id)}${emoji.emoji}`);
                    }}
                    height={350}
                    previewConfig={{ showPreview: false }}
                  />
                </Accordion>
              </React.Fragment>
            );
        }
      }),
    [],
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <FormContainer
          title={_id ? 'Edit In App Message' : 'Create In App Message'}
          onSubmit={submit}
          submitButtonLabel={_id ? 'Update' : 'Create'}
        >
          {renderedFields}
          <Select
            options={appSelectionOptions}
            onChange={onChangeApp}
            value={appSelectedValue}
            label={'App'}
          />
        </FormContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Container noBackground={true}>
          <ApplePushNotificationPreview
            title={watch('title')}
            message={watch('body')}
            time={'Now'}
          />
        </Container>
      </Grid>
    </Grid>
  );
};
