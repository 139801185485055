/* eslint-disable @typescript-eslint/no-explicit-any */
import { keyBy, mapValues } from 'lodash';
import * as Yup from 'yup';

import { useReactFormToolkit } from '@tquinlan1992/react-redux-toolkit';

type UseFormParams<Mutation> = {
  fields: {
    label: string;
    value?: string | number;
    _id: string;
    schema: Yup.AnySchema;
  }[];
  mutation: Mutation;
  additionalArgs?: Record<string, string>;
  fieldsName?: string;
  mapFields?: (fields: Record<string, any>) => any;
  initialValues?: Record<string, any>;
  additionalFields?: Record<string, unknown>;
  resetOnSuccess?: boolean;
};

export type CustomFormParams<Fields extends { value?: string | number; _id: string }> = ({
  label: string;
} & Fields)[];

export const useForm = <Mutation extends () => readonly any[]>({
  fields: fieldsArg,
  mutation,
  additionalArgs,
  fieldsName = 'fields',
  mapFields,
  initialValues,
  additionalFields,
  onSuccess = () => null,
  resetOnSuccess = true,
}: UseFormParams<Mutation> & {
  onSuccess?: (params: typeof initialValues & typeof additionalArgs) => void;
}) => {
  const [save, mutationArgs] = mutation();
  const fieldKeys = keyBy(fieldsArg, '_id');
  const fieldKeysWithValues = keyBy(
    fieldsArg.filter((field) => !!field.value),
    '_id',
  );
  const fieldObject = mapValues(fieldKeys, (element) => element.schema);
  const defaultValues = {
    ...initialValues,
    ...mapValues(fieldKeysWithValues, ({ value }) => value),
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    getValues,
    watch,
    setValue,
    reset,
  } = useReactFormToolkit({
    schema: Yup.object(fieldObject).required(),
    defaultValues,
  });

  const submit = handleSubmit(
    async (fields) => {
      const fieldsToSave = { ...fields, ...additionalFields };
      const newFields = mapFields
        ? mapFields(fields)
        : {
            ...additionalArgs,
            [fieldsName]: fieldsToSave,
          };
      await save(newFields);
      const resetObject = { ...mapValues(fieldObject, () => null), ...defaultValues };
      onSuccess(fieldsToSave);
      resetOnSuccess && reset(resetObject);
    },
    (e) => {
      console.log('error onSubmit', e);
    },
  );

  return {
    control,
    errors,
    submit,
    register,
    mutationArgs,
    fields: fieldsArg,
    getValues,
    watch,
    setValue,
    reset,
  };
};
