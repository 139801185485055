import { useEffect, useState } from 'react';

import { FormControl, InputLabel, Select as MaterialUiSelect, MenuItem } from '@mui/material';

export type SelectProps<ValueType = string | number> = {
  label: string;
  value?: ValueType;
  onChange: (value: ValueType) => void;
  options: { value: ValueType; label: string }[];
};

export const useSelect = <ValueType extends string | number>(
  options: { value: ValueType; label: string }[],
  initialValue?: ValueType,
) => {
  const [value, setValue] = useState<ValueType>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialValue ? initialValue : options[0] ? options[0].value : ('' as any as ValueType),
  );
  useEffect(() => {
    if (options.length) {
      setValue(value || options[0].value);
    }
  }, [options.length]);
  return {
    value,
    onChange: (value: ValueType) => setValue(value),
    options,
  };
};

export const Select = <ValueType extends string | number | boolean>({
  onChange,
  value,
  label,
  options,
}: SelectProps<ValueType>) => (
  <FormControl fullWidth variant="standard">
    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
    <MaterialUiSelect
      value={value}
      label={label}
      onChange={(event) => onChange(event.target.value as ValueType)}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={label} value={value as string}>
          {label}
        </MenuItem>
      ))}
    </MaterialUiSelect>
  </FormControl>
);
