import { map } from 'lodash';
import { chain } from 'lodash';
import { mapKeys, mapValues } from 'lodash';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  getProfileRoute,
  getRootPath,
} from '@cannabis/account-portal-core/AccountPortalAppConfigs';
import { useProfiles } from '@cannabis/account-portal-core/hooks/useProfiles';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { getLuxonDate, getShortDateWithSeconds } from '@cannabis/core/src/dateUtils';
import { Button } from '@tq1992/components/Button';
import { Table, useSortTable } from '@tq1992/components/Table';
import Iconify from '@tq1992/components/minimal-main/components/iconify/Iconify';

export const ProfilesTable = () => {
  const { activeSort, sortDirection, onClickSort } = useSortTable({
    initalDirection: 'desc',
    initialSort: 'lastActivity',
  });
  const [withinMilesOfBoston, setWithinMilesOfBoston] = useState(0);
  const {
    profilesPaginationProps: { data: profiles, pagination, setFilters, isLoading },
    getTagOptionsResult,
  } = useProfiles({
    orderBy: activeSort,
    direction: sortDirection,
    withinMilesOfBoston,
  });
  const { data: segments } = useTaskHandlerPagination('segments');
  if (!profiles) {
    return null;
  } else {
    return (
      <>
        <Link to={getRootPath('CreateProfile')} style={{ textDecoration: 'none' }}>
          <Button>
            <Iconify icon="ic:round-person-add" style={{ marginRight: '4px' }} />
            Create New Profile
          </Button>
        </Link>
        <Table
          {...{ activeSort, sortDirection, onClickSort }}
          isLoading={isLoading}
          filters={{
            onAdd: (newFilters) => {
              const commonFilters = newFilters.filter((filter) => filter.compare !== 'radius');
              const radiusFilters = newFilters.filter((filter) => filter.compare === 'radius');
              setFilters(
                commonFilters.map(({ property, compare, match }) => ({
                  property: String(property),
                  compare,
                  match,
                })),
              );
              if (radiusFilters[0]) {
                setWithinMilesOfBoston(Number(radiusFilters[0].match));
              } else {
                setWithinMilesOfBoston(0);
              }
            },
            keyLabel: 'Tag',
            compareLabel: 'Operator',
            valueLabel: 'Tag Value',
            option: {
              keyOptions: [
                { label: 'Country', value: 'country' },
                { label: 'Miles within Boston', value: 'withinBoston' },
                ...(segments?.map((segment) => ({
                  label: segment.name,
                  value: `segments.${segment._id}.active`,
                })) || []),
                ...(getTagOptionsResult.data
                  ? Object.keys(getTagOptionsResult.data).map((key) => ({
                      label: key,
                      value: `tags.${key}`,
                    }))
                  : []),
              ],
              valueOptions: {
                country: {
                  options: [
                    { label: 'USA', value: 'USA' },
                    { label: 'Mexico', value: 'Mexico' },
                  ],
                  operators: ['=='],
                },
                withinBoston: {
                  options: [],
                  operators: ['radius'],
                },
                ...chain(segments || [])
                  .keyBy((segment) => `segments.${segment._id}.active`)
                  .mapValues(() => ({
                    options: [
                      {
                        label: 'True',
                        value: true,
                      },
                      {
                        label: 'False',
                        value: false,
                      },
                    ],
                    operators: ['=='],
                  }))
                  .value(),
                ...(getTagOptionsResult.data
                  ? mapKeys(
                      mapValues(getTagOptionsResult.data, (tagValues) => ({
                        options: tagValues.map((value: string) => ({ label: value, value })),
                        operators: ['==', '!=', '>', '<'],
                      })),
                      (value, key) => `tags.${key}`,
                    )
                  : {}),
              },
            },
          }}
          pagination={pagination}
          headers={[
            { label: 'Email', value: 'email', sort: true },
            { label: 'Authenticated', value: 'authenticated', sort: true },
            { label: 'Birthday', value: 'birthday', sort: true },
            'Phone Number',
            { label: 'Country', value: 'country', sort: true },
            'Segments',
            'Project',
            { label: 'Apps', value: 'apps', sort: true },
            { label: 'Sessions', value: 'sessionCount', sort: true },
            { label: 'Last Activity', value: 'lastActivity', sort: true },
            'User Id',
            { label: 'Tenant', value: 'tenant', sort: true },
            { label: 'City', value: 'ipInfo.city', sort: true },
          ]}
          data={profiles}
          mapRow={({
            email,
            authenticated,
            birthday,
            phoneNumber,
            country,
            segments,
            projectName,
            appNames,
            sessionCount,
            lastActivity,
            userId,
            tenantName,
            ipInfo = { city: '' },
          }) => [
            <b>{email}</b>,
            authenticated ? 'Yes' : 'No',
            birthday && getLuxonDate(birthday, 'DATE_SHORT'),
            phoneNumber,
            country,
            map(segments, (segment) => segment).filter((segment) => segment.active).length,
            projectName,
            appNames && appNames.length === 1 ? appNames[0] : appNames?.length,
            sessionCount,
            getShortDateWithSeconds(lastActivity),
            userId,
            tenantName,
            ipInfo.city,
          ]}
          rowKey="_id"
          mapRowLink={(row) => getProfileRoute(row._id)}
        />
      </>
    );
  }
};

export const ProfilesPage = () => <ProfilesTable />;
