import { useGetProjectResolver } from './useGetTaskEntityHandler';
import { useTaskHandlerPagination } from './useTaskHandlerPagination';

export const useProjects = () =>
  useTaskHandlerPagination('projects', {
    orderBy: 'lastUpdated',
    direction: 'desc',
    pagination: {
      limit: 10,
    },
  });

export const useProject = (_id: string) => {
  const { data, ...rest } = useGetProjectResolver({
    resolver: 'getProjectWithApps',
    queries: [{ property: '_id', compare: '==', match: _id }],
  });
  return {
    data: data ? data[0] : null,
    ...rest,
  };
};
