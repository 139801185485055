import { Link } from 'react-router-dom';

import { getProjectRoute } from '@cannabis/account-portal-core';
import { useAppOrganizationContext } from '@cannabis/account-portal-core/contexts/AppOrganization';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { Grid } from '@mui/material';
import { Card } from '@tq1992/components/Card';
import { CenterGrid } from '@tq1992/components/CenterGrid';
import { Loading } from '@tq1992/components/Loading';
import { Tabs } from '@tq1992/components/Tabs';

import { ButtonLinkToPage } from '../Navigation';

export const Projects = () => {
  const { organization } = useAppOrganizationContext();

  const { data, isLoading } = useTaskHandlerPagination('projects', {
    orderBy: 'created',
    direction: 'desc',
    pagination: { limit: 10 },
  });
  const cardsRenderd = data?.map((element) => (
    <>
      <Link to={getProjectRoute(element._id)} style={{ textDecoration: 'none' }}>
        <Card label={element.name} />
      </Link>
      <br />
    </>
  ));

  return (
    <Tabs options={['Projects']} appBar={true}>
      <>
        <span>Organization Id: {organization._id}</span> <br /> <br />
        <ButtonLinkToPage to="CreateProject" label="Create Project" iconify="ic:round-add-circle" />
        <br />
        <br />
        {isLoading ? (
          <CenterGrid>
            <Loading />
          </CenterGrid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={1} spacing={2}>
              {cardsRenderd}
            </Grid>
          </Grid>
        )}
      </>
    </Tabs>
  );
};
