import { useTaskHandlerPagination } from './useTaskHandlerPagination';

export const useProducts = (params?: { orderBy: string; direction: 'asc' | 'desc' }) => {
  return useTaskHandlerPagination('products', {
    orderBy: params?.orderBy || '_id',
    direction: params?.direction || 'desc',
    pagination: {
      limit: 5,
    },
  });
};
