/* eslint-disable @typescript-eslint/no-explicit-any */

import { getToken, organizationLocalStorage } from '@cannabis/core/src/sso';

import { createAppApi } from './baseApi';
import { initializedAppApi } from './initializedAppApi';

// Define a service using a base URL and expected endpoints

export let appApi: ReturnType<typeof createWebAppApi>;

export const initializeAppApi = (baseUrl: string) => {
  const result = createWebAppApi(baseUrl);
  appApi = result;
  initializedAppApi.appApi = result;
  return result;
};

export const createWebAppApi = (baseUrl: string) =>
  createAppApi({
    baseUrl,
    getHeaders: async () => {
      return {
        token: (await getToken()) || '',
        tenantId: organizationLocalStorage.get().adminTenantId,
      };
    },
  });
