import { appApi } from '@cannabis/core/src/api/accountPortalApi';
import { usePagination } from '@tq1992/components/Table/usePagination';

import { useAppOrganizationContext } from '../contexts/AppOrganization';

export const useClientOrganizations = () => {
  const { organization } = useAppOrganizationContext();
  return usePagination({
    organizationId: organization._id,
    useQuery: appApi.endpoints.getOrganizations.useQuery,
  });
};
