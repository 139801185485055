import { useSnackbar } from 'notistack';

export const useAccountPortalSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  return {
    enqueueSnackbar: (label: string) =>
      enqueueSnackbar(label, {
        variant: 'success',
        color: 'secondary',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      }),
  };
};
