import Case from 'case';

import { useWebNavigatorContext } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { Tabs } from '@tq1992/components/Tabs';

import { ProfilesPage } from './Profiles';
import { Segments } from './Segments';

export const Profiles = () => {
  const { pathname } = useWebNavigatorContext();
  return (
    <>
      <Tabs
        options={[
          { label: 'Profiles', value: '/profiles' },
          { label: 'Segments', value: '/segments' },
        ]}
        appBar={true}
        link={true}
        value={Case.lower(pathname)}
      >
        <ProfilesPage />
        <Segments />
      </Tabs>
    </>
  );
};
