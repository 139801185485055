import { Tabs } from '@tq1992/components/Tabs';

import { PushTemplates } from './PushTemplates';
import { SendToApp } from './SendToApp';

export const SendPush = () => {
  return (
    <Tabs options={['Push Templates', 'Send To App']}>
      <PushTemplates />
      <SendToApp />
    </Tabs>
  );
};
