import MaterialUiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export const Card = ({
  label,
  value,
  width,
}: {
  label: string;
  value?: string | number;
  width?: string;
}) => {
  return (
    <MaterialUiCard sx={{ minWidth: 275, width }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {label}
        </Typography>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
      </CardContent>
    </MaterialUiCard>
  );
};
