import * as Yup from 'yup';

import { useSetDocumentHandlerEventFormFields } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

export const CreateProfile = () => {
  const { register, submit, fields } = useSetDocumentHandlerEventFormFields('profiles', {
    fields: [
      { label: 'Email', _id: 'email', schema: Yup.string() },
      { label: 'Phone Number', _id: 'phoneNumber', schema: Yup.string() },
      { label: 'First Name', _id: 'firstName', schema: Yup.string() },
      { label: 'Last Name', _id: 'lastName', schema: Yup.string() },
      { label: 'Country', _id: 'country', schema: Yup.string() },
    ],
  });

  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <Tabs options={['Create Profile']} appBar={true}>
      <FormContainer title="Create New Profile" onSubmit={submit} submitButtonLabel="Save User">
        {renderedFields}
      </FormContainer>
    </Tabs>
  );
};
