import * as Yup from 'yup';

import { useMutationFormTasksHandlerEvent } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Select, useSelect } from '@tq1992/components/Select';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

export const CreateCustomerUser = () => {
  const { data: tenants } = useTaskHandlerPagination('customerTenants');

  const { options, onChange, value } = useSelect(
    tenants?.map((tenant) => ({ value: tenant.tenantId, label: tenant.name })) || [],
  );

  const { register, submit, fields } = useMutationFormTasksHandlerEvent(
    'createCustomerUser',
    [
      { label: 'Email', _id: 'email', schema: Yup.string().required() },
      { label: 'Password', _id: 'password', schema: Yup.string().required() },
    ],
    {
      tenantId: value,
    },
  );
  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <Tabs options={['Create Customer User']} appBar={true}>
      <FormContainer title="Create Customer User" onSubmit={submit} submitButtonLabel="Create">
        {renderedFields}
        <Select options={options} onChange={onChange} value={value} label={'Tenant'} />
      </FormContainer>
    </Tabs>
  );
};
