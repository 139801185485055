import * as Yup from 'yup';

import { appApi } from '@cannabis/core/src/api/accountPortalApi';
import { useReactFormToolkit } from '@tquinlan1992/react-redux-toolkit';

import { useAppOrganizationContext } from '../contexts/AppOrganization';

export const useCreateOrganization = () => {
  const { organization } = useAppOrganizationContext();
  const [createOrganization, mutationVariables] = appApi.endpoints.createOrganization.useMutation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useReactFormToolkit({
    schema: Yup.object({
      name: Yup.string().required(),
      user: Yup.object({
        email: Yup.string().required(),
        password: Yup.string().required(),
      }).required(),
    }),
  });

  const submit = handleSubmit(
    async ({ name: organizationName, user }) => {
      await createOrganization({
        organizationId: organization?._id || '',
        name: organizationName,
        user,
      });
    },
    (e) => {
      console.log('error onSubmit', e);
    },
  );

  return {
    control,
    errors,
    submit,
    register,
    ...mutationVariables,
  };
};
