import { useTaskHandlerPagination } from './useTaskHandlerPagination';

export const useLocations = () => {
  const {
    data: locations,
    pagination,
    isLoading,
  } = useTaskHandlerPagination('locations', {
    orderBy: 'created',
    direction: 'desc',
    pagination: { limit: 10 },
  });
  return {
    locations: locations || [],
    isLoading,
    pagination,
  };
};
