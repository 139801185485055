import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useSetDocumentHandlerEventFormFields } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useProject } from '@cannabis/account-portal-core/hooks/useProject';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

export const CreateAppBody = ({ projectId }: { projectId: string }) => {
  const { fields, register, submit } = useSetDocumentHandlerEventFormFields('apps', {
    fields: [{ label: 'Name', _id: 'name', schema: Yup.string().required() }],
    defaultArgs: {
      projectId,
    },
    successMessage: (params) => `App "${params?.name}" Created`,
  });
  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <FormContainer title="" onSubmit={submit} submitButtonLabel="Save">
      {renderedFields}
    </FormContainer>
  );
};

export const CreateApp = () => {
  const { projectId } = useParams();
  const { data } = useProject(projectId as string);
  return (
    <Tabs options={['Create App']} appBar={true}>
      {data ? <CreateAppBody projectId={data?._id} /> : <>Project not found</>}
    </Tabs>
  );
};
