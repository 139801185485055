import { useState } from 'react';

import { createAppContext } from '@cannabis/core/src/createAppContext';
import { AppDecodedToken } from '@cannabis/functions/middleware/authenticate';

const useAppOrganization = () => {
  const [organization, setOrganization] = useState<AppDecodedToken['organization']>();
  return {
    organization: organization as AppDecodedToken['organization'],
    setOrganization,
  };
};

export const {
  useContext: useAppOrganizationContext,
  ContextProvider: AppOrganizationContextProvider,
} = createAppContext(useAppOrganization);
