import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useAppOrganizationContext } from '@cannabis/account-portal-core/contexts/AppOrganization';
import { useWebNavigatorContext } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { useAccountPortalSnackbar } from '@cannabis/account-portal-core/hooks/useAccountPortalSnackbar';
import { useGetTaskEntityHandler } from '@cannabis/account-portal-core/hooks/useGetTaskEntityHandler';
import { useSegmentsPagination } from '@cannabis/account-portal-core/hooks/useSegments';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { useSetMutationHandlerEvent } from '@cannabis/core/src/api/hooks/useSetMutationHandlerEvent';
import { createAppContext } from '@cannabis/core/src/createAppContext';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Loading } from '@tq1992/components/Loading';
import { Select, useSelect } from '@tq1992/components/Select';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';
import { useReactFormToolkit } from '@tquinlan1992/react-redux-toolkit';

import { SegmentsSelectTable } from '../Messaging/SegmentsSelectTable';

const { ContextProvider, useContext: useCreateJourneyContext } = createAppContext(
  ({ _id }: { _id?: string }) => {
    const { organization } = useAppOrganizationContext();

    const { data: initialValues } = useGetTaskEntityHandler('journeys', [
      {
        property: '_id',
        compare: '==',
        match: _id || '',
      },
    ]);

    const { data: projects } = useTaskHandlerPagination('projects', { orderBy: 'name' });

    const nameInputProps = useReactFormToolkit({
      schema: Yup.object({
        name: Yup.string().required(),
      }),
      defaultValues: {
        name: initialValues?.name,
      },
    });

    const selectProjectProps = useSelect(
      [{ label: 'None', value: initialValues?.projectId || '' }].concat(
        projects?.map((project) => ({ value: project._id, label: project.name })) || [],
      ),
    );
    const selectSegmentProps = useSegmentsPagination({
      initialSelectedRows: initialValues?.segments,
    });

    const sendPushProps = useReactFormToolkit({
      schema: Yup.object({
        title: Yup.string(),
        body: Yup.string().required(),
        delay: Yup.number(),
      }),
      defaultValues: {
        title: initialValues?.messageArgs.title,
        body: initialValues?.messageArgs.body,
        delay: initialValues?.messageArgs.delay,
      },
    });

    const { data: apps } = useTaskHandlerPagination('apps', {
      orderBy: 'name',
      filters: [
        {
          property: 'projectId',
          compare: '==',
          match: selectProjectProps.value,
        },
      ],
    });

    const selectAppProps = useSelect(
      [{ label: 'None', value: initialValues?.messageArgs.appId || '' }].concat(
        apps?.map((app) => ({ value: app._id, label: app.name })) || [],
      ),
    );
    const { save } = useSetMutationHandlerEvent('journeys', organization._id);

    const submit = async () => {
      try {
        const projectId = selectProjectProps.value;
        const name = nameInputProps.getValues().name;
        const appId = selectAppProps.value;
        const title = sendPushProps.getValues().title;
        const body = sendPushProps.getValues().body;
        const delay = sendPushProps.getValues().delay;
        save({
          name,
          projectId,
          segments: selectSegmentProps.selectRow.selected,
          messageArgs: {
            appId,
            title,
            body,
            type: 'push',
            delay: Number(delay),
          },
          _id,
        });
      } catch (e) {
        console.log('error submitting');
      }
    };

    return {
      nameInputProps,
      selectProjectProps,
      selectSegmentProps,
      sendPushProps,
      selectAppProps,
      submit,
      editing: !!_id,
    };
  },
);

const SelectProject = () => {
  const { selectProjectProps } = useCreateJourneyContext();

  return (
    <Select
      options={selectProjectProps.options}
      onChange={selectProjectProps.onChange}
      value={selectProjectProps.value}
      label={'Project'}
    />
  );
};

const SendPush = ({ submit }: { submit: () => void }) => {
  const {
    sendPushProps: { register },
    selectAppProps: { options, onChange, value },
    editing,
  } = useCreateJourneyContext();

  const onSubmit = async () => {
    submit();
  };

  return (
    <>
      <FormContainer
        title="Push Notification"
        onSubmit={onSubmit}
        submitButtonLabel={editing ? 'Update Journey' : 'Create Journey'}
      >
        <TextField {...register('title')} label="Title" />
        <TextField {...register('body')} label="Body" />
        <TextField {...register('delay')} label="Delay in seconds" type="number" />
        <Select options={options} onChange={onChange} value={value} label={'App'} />
      </FormContainer>
    </>
  );
};

const NameInput = () => {
  const { nameInputProps } = useCreateJourneyContext();
  return <TextField {...nameInputProps.register('name')} label="Name" />;
};

const JourneyComponent = () => {
  const { enqueueSnackbar } = useAccountPortalSnackbar();
  const { selectSegmentProps, submit, nameInputProps, editing } = useCreateJourneyContext();
  const navigate = useWebNavigatorContext();
  const onSumbit = async () => {
    await submit();
    enqueueSnackbar(
      `Journey "${nameInputProps.getValues().name}" ${editing ? 'Updated' : 'Created'}`,
    );
    navigate.Journeys();
  };
  return (
    <>
      <h1>On Segment Enter</h1>
      <NameInput />
      <SelectProject />
      <SegmentsSelectTable {...selectSegmentProps} />
      <SendPush submit={onSumbit} />
    </>
  );
};

export const EditJourney = () => {
  const { _id } = useParams();
  const { isLoading } = useGetTaskEntityHandler('journeys', [
    {
      property: '_id',
      compare: '==',
      match: _id || '',
    },
  ]);
  return (
    <Tabs options={[_id ? 'Edit Journey' : 'Create Journey']} appBar={true}>
      {!isLoading || !_id ? (
        <ContextProvider args={{ _id }}>
          <JourneyComponent />
        </ContextProvider>
      ) : (
        <Loading />
      )}
    </Tabs>
  );
};
