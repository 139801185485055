import React from 'react';

import { TextField as MaterialTextField } from '@mui/material';

export interface Props {
  label: string;
  placeholder?: string;
  multiline?: boolean;
  errorMessage?: string;
  required?: boolean;
  type?: 'number' | 'text';
  onChangeValue?: (value: string) => void;
}

export const TextField: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  ({ errorMessage, onChangeValue, ...props }, ref) => {
    const onChange = onChangeValue
      ? {
          onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            onChangeValue(event.target.value),
        }
      : {};
    return (
      <MaterialTextField
        error={!!errorMessage}
        helperText={errorMessage}
        {...props}
        ref={ref}
        variant="standard"
        type={props.type}
        {...onChange}
      />
    );
  },
);
