import { Box } from '@mui/system';

export const PageContainer = ({
  children,
  sidebar,
}: {
  children: JSX.Element;
  sidebar?: JSX.Element;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '0',
        margin: '0',
      }}
    >
      {sidebar}
      <div
        style={{
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: '0',
          padding: '0',
          margin: '0',
          width: '100%',
        }}
      >
        {children}
      </div>
    </Box>
  );
};
