import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { getToken } from '@cannabis/core/src/sso';
import { CenterGrid } from '@tq1992/components/CenterGrid';
import { Loading } from '@tq1992/components/Loading';

import { IntializeAuthParams, initializeAuth, signIn } from './initialize';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const signInRoutes = (signInPage: JSX.Element) => (
  <>
    <Route path="/sign-in" element={signInPage} />
    <Route path="*" element={<Navigate replace to="/sign-in" />} />
  </>
);

const useInitialize = (params: IntializeAuthParams) => {
  const [userToken, setUserToken] = useState<string | void>();

  useEffect(() => {
    const run = async () => {
      try {
        await initializeAuth(params);
        const token = await getToken();
        setUserToken(token || '');
      } catch (e) {
        setUserToken('');
      }
    };
    run();
  }, []);
  return userToken;
};

export type AppRoutesParams = {
  signInPage: (onSignIn: () => void) => JSX.Element;
  protectedRoutes: JSX.Element;
  config: IntializeAuthParams;
};

export const AppRoutes: React.FC<AppRoutesParams> = ({ signInPage, protectedRoutes, config }) => {
  const userToken = useInitialize(config);
  if (userToken === undefined) {
    return (
      <CenterGrid>
        <Loading />
      </CenterGrid>
    );
  }
  const page = !userToken ? <Routes>{signInRoutes(signInPage(signIn))}</Routes> : protectedRoutes;
  return page;
};
