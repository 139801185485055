import { configureSlices } from '@tquinlan1992/react-redux-toolkit';

import { exampleSlice } from '../slices/example';

const slices = { example: exampleSlice };

export const {
  storeSelectors,
  reducer,
  storeActions: storeActionsFromConfigured,
} = configureSlices(slices);
