import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';

import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { Select, SelectProps } from '../Select';
import { TextField } from '../TextField';

export type CompareOperators = '==' | 'in' | '!=' | '>' | '<' | 'radius';

export type FiltersResult<KeyOptions> = {
  property: KeyOptions;
  compare: CompareOperators;
  match: string;
}[];

export type FiltersProps<KeyOptions extends SelectProps['options'] = SelectProps['options']> = {
  option: {
    keyOptions: KeyOptions;
    valueOptions: Record<
      KeyOptions[number]['value'],
      {
        options: { value: string | number | boolean; label: string }[];
        operators: CompareOperators[];
      }
    >;
  };
  keyLabel?: string;
  valueLabel?: string;
  compareLabel?: string;
  onAdd: (params: FiltersResult<KeyOptions>) => void;
};

const getCompareField = (value: CompareOperators): { label: string; value: CompareOperators } => {
  switch (value) {
    case '==':
      return { label: 'Equals', value };
    case '!=':
      return { label: 'Not Equals', value };
    case '<':
      return { label: 'Less Than', value };
    case '>':
      return { label: 'Greater Than', value };
    case 'radius':
      return { label: 'Within', value };
    default:
      return { label: 'Operator not defined for label', value };
  }
};

export const Filters = <KeyOptions extends SelectProps['options']>({
  option,
  keyLabel,
  valueLabel,
  onAdd,
  compareLabel,
}: FiltersProps<KeyOptions>) => {
  const [property, setProperty] = useState<KeyOptions[number]['value']>('');
  const [compare, setCompare] = useState<CompareOperators | ''>('');
  const [match, setMatch] = useState<number | string | boolean>('');
  const [filters, setFilters] = useState<FiltersResult<KeyOptions>>([]);
  const removeFilter = (index: number) => {
    filters.splice(index, 1);
    updateFilters(filters);
  };

  const updateFilters = (newFilters: FiltersResult<KeyOptions>) => {
    setFilters(newFilters);
    onAdd(newFilters);
  };

  const onAddFilter = () => {
    const newFilters = [...filters, { property, compare, match }];
    updateFilters(newFilters as FiltersResult<KeyOptions>);
    setProperty('');
    setCompare('');
    setMatch('');
  };

  useEffect(() => {
    if (property) {
      const keyOperators = option.valueOptions[property].operators;
      if (keyOperators) {
        setCompare(keyOperators[0]);
      }
    }
  }, [property]);

  const filtersList = filters.map(({ property, compare, match }, index) => {
    return (
      <Typography variant="subtitle1">
        <IconButton
          onClick={() => removeFilter(index)}
          tooltip="Remove"
          icon="material-symbols:delete-rounded"
        />
        <>
          Key: {property}, Operator: {compare}, Value: {String(match)}
        </>
      </Typography>
    );
  });
  const compareOptions = property
    ? option.valueOptions[property].operators.map(getCompareField)
    : [];
  const MatchInput = ['==', '!=', ''].includes(compare) ? (
    <Select<string | number | boolean>
      options={property ? option.valueOptions[property].options : []}
      label={valueLabel || 'Value'}
      value={match}
      onChange={(newValue) => setMatch(newValue)}
    />
  ) : (
    <TextField label={valueLabel || 'Value'} onChangeValue={setMatch} />
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <Select
          options={option.keyOptions}
          label={keyLabel || 'Key'}
          value={property}
          onChange={(newValue) => setProperty(newValue as string)}
        />
        <Select
          options={compareOptions || []}
          label={compareLabel || 'Operator'}
          value={compare}
          onChange={(newValue) => setCompare(newValue)}
        />
        {MatchInput}
        <br />
        <br />
        <Button onClick={onAddFilter}> Add </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        {filtersList}
      </Grid>
    </Grid>
  );
};
