import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useWebNavigate } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { useGetTaskEntityHandler } from '@cannabis/account-portal-core/hooks/useGetTaskEntityHandler';
import { useMutationFormCallAction } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { Grid } from '@mui/material';
import { Container } from '@tq1992/components/Container';
import { DateTimePicker } from '@tq1992/components/DateTimePicker';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Loading } from '@tq1992/components/Loading';
import { ApplePushNotificationPreview } from '@tq1992/components/PushNotificationPreview';
import { Select, useSelect } from '@tq1992/components/Select';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';
import { ToggleButtonGroup } from '@tq1992/components/ToggleButtonGroup';

import { CreateAbanondedCart } from './sendPush/CreateAbanondedCart';
import { CreateInAppMessage } from './sendPush/CreateInAppMessage';

export const useAutomatedMessageFromId = (_id: string) =>
  useGetTaskEntityHandler('automatedMessages', [
    {
      property: '_id',
      compare: '==',
      match: _id,
    },
  ]);

const EditAutomatedPush = ({ _id }: { _id: string }) => {
  const { data, isLoading } = useAutomatedMessageFromId(_id);
  const { data: projects } = useTaskHandlerPagination('projects', { orderBy: 'name' });
  const navigate = useWebNavigate();

  const {
    options: projectOptions,
    onChange: onChangeProject,
    value: projectSelectedValue,
  } = useSelect(
    [{ label: 'None', value: '' }].concat(
      projects?.map((project) => ({ value: project._id, label: project.name })) || [],
    ),
    data?.type === 'ABANDONED_CART' || data?.type === 'PUSH' ? data?.projectId : '',
  );
  const { fields, register, submit, setValue, watch } = useMutationFormCallAction(
    'updateAutomatedMessage',
    {
      initialValues: data?.type === 'PUSH' ? { ...data, messageId: data._id as string } : undefined,
      fields: [
        {
          _id: 'title',
          label: 'Title',
          schema: Yup.string().optional(),
        },
        {
          _id: 'body',
          label: 'Body',
        },
        {
          _id: 'date',
          label: 'Time',
        },
        {
          _id: 'daysOfTheWeek',
          label: 'Days of the week',
          schema: Yup.array(),
        },
      ],
    },
    {
      projectId: projectSelectedValue,
      successMessage: () => {
        navigate.AutomatedMessages();
        return 'Message Updated';
      },
      messageId: _id,
    },
  );
  const renderedFields = fields.map((field) => {
    if (data?.type === 'PUSH') {
      switch (field._id) {
        case 'date':
          return (
            <DateTimePicker
              type="time"
              key={field._id}
              onChange={(value) => setValue(field._id, value)}
              initialValue={data?.date}
            />
          );
        case 'daysOfTheWeek':
          return (
            <ToggleButtonGroup
              label="Days of the week"
              options={[
                {
                  label: 'Monday',
                  value: 0,
                },
                {
                  label: 'Tuesday',
                  value: 1,
                },
                {
                  label: 'Wednesday',
                  value: 2,
                },
                {
                  label: 'Thursday',
                  value: 3,
                },
                {
                  label: 'Friday',
                  value: 4,
                },
                {
                  label: 'Saturday',
                  value: 5,
                },
                {
                  label: 'Sunday',
                  value: 6,
                },
              ]}
              initialValues={watch(field._id)}
              onChange={(newValues) => setValue(field._id, newValues)}
            />
          );
        default:
          return <TextField key={field._id} label={field.label} {...register(field._id)} />;
      }
    } else {
      return <></>;
    }
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <FormContainer title="Update Daily Push" onSubmit={submit} submitButtonLabel="Save">
          {renderedFields}
          <Select
            options={projectOptions}
            onChange={onChangeProject}
            value={projectSelectedValue}
            label={'Project'}
          />
        </FormContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Container noBackground={true}>
          <ApplePushNotificationPreview
            title={watch('title')}
            message={watch('body')}
            time={'Now'}
          />
        </Container>
      </Grid>
    </Grid>
  );
};

export const EditAutomatedMessage = () => {
  const { _id } = useParams();
  const { isLoading, data } = useAutomatedMessageFromId(_id as string);
  const getEditMessage = () => {
    switch (data?.type) {
      case 'PUSH':
        return <EditAutomatedPush _id={_id as string} />;
      case 'ABANDONED_CART':
        return <CreateAbanondedCart _id={_id as string} />;
      case 'IN_APP_MESSAGE':
        return <CreateInAppMessage _id={_id as string} />;
    }
  };
  return (
    <Tabs options={['Edit Automated Message']} appBar={true}>
      {isLoading ? <Loading /> : getEditMessage()}
    </Tabs>
  );
};
