/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext as reactUseContext } from 'react';

export const createAppContext = <Hook extends (args?: any) => any>(hook: Hook) => {
  const Context = createContext<ReturnType<typeof hook>>({} as any);

  const ContextProvider: React.FC<{ children: JSX.Element; args?: Parameters<Hook>[0] }> = ({
    children,
    args,
  }) => {
    return <Context.Provider value={hook(args)}>{children}</Context.Provider>;
  };

  const useContext = () => {
    return reactUseContext(Context);
  };

  return {
    ContextProvider,
    useContext,
  };
};

export const createAppContextProvider = (
  providers: React.FC<{ children: JSX.Element }>[],
): React.FC<{ children: JSX.Element }> => {
  if (providers[0]) {
    const ProviderToRender = providers[0];
    const NextProvider = createAppContextProvider(providers.slice(1));
    if (NextProvider) {
      return ({ children }: { children: JSX.Element }) => (
        <ProviderToRender>
          <NextProvider>{children}</NextProvider>
        </ProviderToRender>
      );
    } else {
      return ({ children }: { children: JSX.Element }) => (
        <ProviderToRender>{children}</ProviderToRender>
      );
    }
  } else {
    return ({ children }: { children: JSX.Element }) => <>{children}</>;
  }
};
