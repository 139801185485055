import * as Yup from 'yup';

import { useMutationFormTasksHandlerEvent } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

export const CreateCustomerTenant = () => {
  const { register, submit, fields } = useMutationFormTasksHandlerEvent('createCustomerTenant', [
    { label: 'Name', _id: 'name', schema: Yup.string().required() },
  ]);

  const renderedFields = fields.map((field) => (
    <TextField key={field._id} label={field.label} {...register(field._id)} />
  ));
  return (
    <Tabs options={['Create Customer Tenant']} appBar={true}>
      <FormContainer title="Create Customer Tenant" onSubmit={submit} submitButtonLabel="Create">
        {renderedFields}
      </FormContainer>
    </Tabs>
  );
};
