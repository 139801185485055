/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useAppOrganizationContext } from '@cannabis/account-portal-core/contexts/AppOrganization';
import { usePageContainerContext } from '@cannabis/account-portal-core/contexts/PageContainer';
import { useWebNavigatorContext } from '@cannabis/account-portal-core/contexts/WebNavigate';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CodeIcon from '@mui/icons-material/Code';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import House from '@mui/icons-material/House';
import Inventory from '@mui/icons-material/Inventory';
import LocationCity from '@mui/icons-material/LocationCity';
import Logout from '@mui/icons-material/Logout';
import Menu from '@mui/icons-material/Menu';
import NotificationAdd from '@mui/icons-material/NotificationAdd';
import People from '@mui/icons-material/People';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import ShoppingCartCheckout from '@mui/icons-material/ShoppingCartCheckout';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WavesIcon from '@mui/icons-material/Waves';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import palette from '@tq1992/components/minimal-main/theme/palette';

import { getRootPath } from '../..';

const MyListItem = styled(ListItem)`
  .MuiTouchRipple-child {
    background-color: ${(palette as any).primary.dark};
  }
`;

const drawerWidth = 240;

export const Sidebar = ({ logo }: { logo?: JSX.Element }) => {
  const theme = useTheme();
  const { signOut } = usePageContainerContext();
  const { organization } = useAppOrganizationContext();
  const navigate = useWebNavigatorContext();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const location = useLocation();

  const navigationItems: {
    path?: string;
    label: string;
    onClick?: () => void;
    Icon: typeof House;
    additionalActivePaths?: string[];
  }[] = [
    {
      path: getRootPath('Dashboard'),
      label: 'Dashboard',
      onClick: navigate.Dashboard,
      Icon: House,
    },
    {
      path: getRootPath('Integrations'),
      label: 'Integrations',
      onClick: navigate.Integrations,
      Icon: SettingsInputCompositeIcon,
    },
    {
      path: getRootPath('Organizations'),
      label: 'Organizations',
      onClick: navigate.Organizations,
      Icon: CorporateFareIcon,
      additionalActivePaths: [getRootPath('CreateOrganization')],
    },
    {
      path: getRootPath('Projects'),
      label: 'Projects',
      Icon: CodeIcon,
      additionalActivePaths: [getRootPath('CreateProject')],
    },
    {
      path: getRootPath('Profiles'),
      label: 'Profiles',
      onClick: navigate.Profiles,
      Icon: AssignmentIndIcon,
      additionalActivePaths: [getRootPath('Segments'), getRootPath('CreateProfile')],
    },
    {
      path: getRootPath('Email'),
      label: 'Messaging',
      onClick: navigate.Email,
      Icon: NotificationAdd,
      additionalActivePaths: [
        getRootPath('Sms'),
        getRootPath('Push'),
        getRootPath('MessageHistory'),
      ],
    },
    {
      path: getRootPath('AdminUsers'),
      label: 'Admin Users',
      onClick: navigate.AdminUsers,
      Icon: SupervisorAccountIcon,
    },
    {
      path: getRootPath('CustomerUsers'),
      label: 'Customer Users',
      onClick: navigate.CustomerUsers,
      Icon: People,
    },
    {
      path: getRootPath('Onboarding'),
      label: 'Onboarding',
      onClick: navigate.CustomerUsers,
      Icon: AssignmentIcon,
    },
    {
      path: getRootPath('Locations'),
      label: 'Locations',
      onClick: navigate.Locations,
      Icon: LocationCity,
      additionalActivePaths: [getRootPath('CreateLocation')],
    },
    {
      path: getRootPath('Products'),
      label: 'Products',
      onClick: navigate.Products,
      Icon: Inventory,
      additionalActivePaths: [getRootPath('CreateProduct')],
    },
    {
      path: getRootPath('Orders'),
      label: 'Orders',
      onClick: navigate.Orders,
      Icon: ShoppingCartCheckout,
    },
    {
      path: getRootPath('Logs'),
      label: 'Logs',
      onClick: navigate.Logs,
      Icon: WavesIcon,
    },
    {
      label: 'Signout',
      onClick: () => {
        signOut();
      },
      Icon: Logout,
    },
  ];

  const drawer = (
    <>
      <LogoBox>{logo}</LogoBox>
      <Typography variant="h4" align="center" gutterBottom>
        {organization?.name}
      </Typography>
      <List>
        {navigationItems.map(({ label, onClick, Icon, path, additionalActivePaths }) => {
          const selected = [path, ...(additionalActivePaths || [])].includes(location.pathname);
          const color = selected ? theme.palette.primary.dark : '#8A8B9F';
          return (
            <Link
              onClick={onClick}
              key={label}
              to={(path as string) || ''}
              style={{
                textDecoration: 'none',
              }}
            >
              <MyListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon
                    sx={{
                      maxWidth: '30px',
                      minWidth: '2rem',
                      marginLeft: '.5rem',
                      marginTop: '2px',
                    }}
                  >
                    <Icon fontSize="small" htmlColor={color} />
                  </ListItemIcon>
                  <ListItemText sx={{ color, fontSize: '2px' }} primary={label} />
                </ListItemButton>
              </MyListItem>
            </Link>
          );
        })}
      </List>
    </>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ margin: 0, padding: 0 }}
        >
          <Menu />
        </IconButton>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

const LogoBox = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  margin-bottom: 12px;
`;
