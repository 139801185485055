import { useState } from 'react';

import { TimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTimePicker as MaterialDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';

import { TextField } from './TextField';

export const DateTimePicker = (
  props: Omit<Parameters<typeof MaterialDateTimePicker>[0], 'renderInput' | 'value'> & {
    type?: 'date&time' | 'time';
    initialValue?: string;
  },
) => {
  const [value, setValue] = useState<any>(props.initialValue || null);
  const propType = props.type || 'date&time';
  const PickerComponent = propType === 'date&time' ? MaterialDateTimePicker : TimePicker;
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <PickerComponent
        // {...props}
        label="Date&Time picker"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          props.onChange(newValue.toJSDate().toISOString());
        }}
        renderInput={(params: any) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};
