import EmojiPicker from 'emoji-picker-react';
import React from 'react';
import * as Yup from 'yup';

import { useWebNavigate } from '@cannabis/account-portal-core/contexts/WebNavigate';
import { useMutationFormCallAction } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { Grid } from '@mui/material';
import { Accordion } from '@tq1992/components/Accordion';
import { Container } from '@tq1992/components/Container';
import { FormContainer } from '@tq1992/components/FormContainer';
import { ApplePushNotificationPreview } from '@tq1992/components/PushNotificationPreview';
import { Select, useSelect } from '@tq1992/components/Select';
import { TextField } from '@tq1992/components/TextField';

import { useAutomatedMessageFromId } from '../EditAutomatedMessage';

const getUseAutomatedMessageFromId = (_id?: string) => {
  if (_id) {
    return () => useAutomatedMessageFromId(_id);
  } else {
    return () => ({ data: null });
  }
};

export const CreateAbanondedCart = ({ _id }: { _id?: string }) => {
  const { data: projects } = useTaskHandlerPagination('projects', { orderBy: 'name' });
  const { data: automatedMessage } = getUseAutomatedMessageFromId(_id)();
  const navigate = useWebNavigate();
  const {
    options: projectOptions,
    onChange: onChangeProject,
    value: projectSelectedValue,
  } = useSelect(
    [{ label: 'None', value: '' }].concat(
      projects?.map((project) => ({ value: project._id, label: project.name })) || [],
    ),
    automatedMessage?.type === 'ABANDONED_CART' ? automatedMessage?.projectId : '',
  );
  const useEvent = _id ? 'updateAbandonedCartPush' : 'createAbandonedCartPush';
  const { fields, register, submit, setValue, getValues, watch } = useMutationFormCallAction(
    useEvent,
    {
      initialValues: automatedMessage?.type === 'ABANDONED_CART' ? automatedMessage : {},
      fields: [
        {
          _id: 'title',
          label: 'Title',
          schema: Yup.string().optional(),
        },
        {
          _id: 'body',
          label: 'Body',
        },
      ],
    },
    {
      projectId: projectSelectedValue,
      successMessage: () => {
        navigate.AutomatedMessages();
        return _id ? 'Message Updated' : 'Message Created';
      },
      type: 'ABANDONED_CART',
    },
  );
  const renderedFields = React.useMemo(
    () =>
      fields.map((field) => {
        switch (field._id) {
          default:
            return (
              <React.Fragment key={field._id}>
                <TextField key={field._id} label={field.label} {...register(field._id)} />
                <Accordion header="Emoji Keyboard 🍃🔥💨">
                  <EmojiPicker
                    key={field._id}
                    onEmojiClick={(emoji) => {
                      setValue(field._id, `${getValues(field._id)}${emoji.emoji}`);
                    }}
                    height={350}
                    previewConfig={{ showPreview: false }}
                  />
                </Accordion>
              </React.Fragment>
            );
        }
      }),
    [],
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <FormContainer
          title={_id ? 'Edit Abandoned Cart Message' : 'Create Abandoned Cart Message'}
          onSubmit={submit}
          submitButtonLabel={_id ? 'Update' : 'Create'}
        >
          {renderedFields}
          <Select
            options={projectOptions}
            onChange={onChangeProject}
            value={projectSelectedValue}
            label={'Project'}
          />
        </FormContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Container noBackground={true}>
          <ApplePushNotificationPreview
            title={watch('title')}
            message={watch('body')}
            time={'Now'}
          />
        </Container>
      </Grid>
    </Grid>
  );
};
