import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { appApi } from '@cannabis/core/src/api/accountPortalApi';
import { EntityKey, EntityModels, TaskHandlers } from '@cannabis/functions/express/tasksRouter';
import { CompareOperators } from '@tq1992/components/Table/Filters';
import { usePagination } from '@tq1992/components/Table/usePagination';

import { useAppOrganizationContext } from '../contexts/AppOrganization';

export const useTaskHandlerPagination = <Entity extends EntityKey>(
  entity: Entity,
  args?: Partial<
    Omit<
      Parameters<TaskHandlers['entityGetDocuments']>[0]['args'],
      'queries' | 'entity' | 'organizationId'
    >
  > & {
    pollingInterval?: number;
    filters?: { property: string; compare: CompareOperators; match: string | string[] }[];
    initialSelectedRows?: string[];
  },
) => {
  const [filters, setFilters] = useState<
    { property: string; compare: CompareOperators; match: string | string[] }[]
  >(args?.filters || []);
  useDeepCompareEffect(() => {
    setFilters(args?.filters || []);
  }, [args?.filters || []]);
  const { organization } = useAppOrganizationContext();
  const result = usePagination({
    organizationId: organization?._id || '',
    ...args,
    useQuery: (paginationArgs) => {
      return appApi.endpoints.tasksHandlerGet.useQuery(
        {
          args: {
            ...paginationArgs,
            queries: filters,
            entity,
            orderBy: args?.orderBy,
          },
          organizationId: organization?._id,
          event: 'entityGetDocuments',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
        { pollingInterval: args?.pollingInterval },
      );
    },
    initialSelectedRows: args?.initialSelectedRows,
  });
  useDeepCompareEffect(() => {
    result.pagination.handleSetPage(0);
  }, [args?.direction, args?.orderBy, args?.filters || [], args?.mongoQuery]);

  return {
    ...result,
    data: (result.data || []) as Awaited<ReturnType<EntityModels[Entity]['getDocuments']>>['data'],
    pagination: result.pagination,
    setFilters,
    filters,
  };
};
