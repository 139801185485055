import ReactJson, { ReactJsonViewProps } from 'react-json-view';

export const JsonViewer = (props: ReactJsonViewProps) => (
  <ReactJson
    {...{
      name: false,
      collapsed: false,
      displayDataTypes: false,
      displayObjectSize: false,
      enableClipboard: false,
      ...props,
    }}
  />
);
