import { Link } from 'react-router-dom';

import { getEmailTemplateRoute } from '@cannabis/account-portal-core/AccountPortalAppConfigs';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { Grid } from '@mui/material';
import { Loading } from '@tq1992/components/Loading';

import { ButtonLinkToPage } from '../../Navigation';
import { EmailPreview } from './EmailPreview';

const Item = ({ title, html, _id }: { title: string; html: string; _id: string }) => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <div
        style={{
          height: '200px',
          overflow: 'hidden',
          borderStyle: 'solid',
          borderColor: 'grey',
          borderWidth: '1px',
          backgroundColor: 'white',
        }}
      >
        <Link to={getEmailTemplateRoute(_id)} style={{ textDecoration: 'none' }}>
          <>
            <h1 style={{ textAlign: 'center', color: 'black' }}>{title}</h1>
            <EmailPreview html={html} />
          </>
        </Link>
      </div>
    </Grid>
  );
};

export const EmailTemplates = () => {
  const { data, isLoading } = useTaskHandlerPagination('templates', {
    filters: [{ property: 'type', compare: '==', match: 'email' }],
  });
  console.log('templates', data);
  const templates = data?.map((template) => {
    if (template.type === 'email') {
      return <Item title={template.title} html={template.value} _id={template._id} />;
    } else {
      return null;
    }
  });
  return (
    <>
      <ButtonLinkToPage
        to="CreateEmailTemplate"
        label="Create Template"
        iconify="ic:round-add-circle"
      />
      {isLoading ? (
        <Loading />
      ) : (
        <Grid container spacing={3} gridTemplateRows="10px">
          {templates}
        </Grid>
      )}
    </>
  );
};
