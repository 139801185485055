import { Link } from 'react-router-dom';

import {
  getOnboardingTemplateRoute,
  getRootPath,
} from '@cannabis/account-portal-core/AccountPortalAppConfigs';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { Button } from '@tq1992/components/Button';
import { Table } from '@tq1992/components/Table';
import { Tabs } from '@tq1992/components/Tabs';
import Iconify from '@tq1992/components/minimal-main/components/iconify/Iconify';

export const Onboarding = () => {
  const { data, isLoading, pagination } = useTaskHandlerPagination('templates', {
    orderBy: 'lastUpdated',
    direction: 'desc',
    pagination: {
      limit: 10,
    },
    filters: [{ property: 'type', compare: '==', match: 'onboarding' }],
  });
  return (
    <Tabs options={['Onboarding Templates']} appBar={true} link={true}>
      <>
        <Link to={getRootPath('CreateOnboardingTemplate')} style={{ textDecoration: 'none' }}>
          <Button>
            <Iconify icon="ic:round-person-add" style={{ marginRight: '4px' }} />
            Create New Template
          </Button>
        </Link>
        <Table
          isLoading={isLoading}
          headers={['Title', 'Verify Email Template', 'Last Updated', '_id']}
          data={data || []}
          mapRow={(args) => {
            switch (args.type) {
              case 'onboarding':
                return [
                  args.title,
                  args.verifyEmailTemplate,
                  args.lastUpdated ? new Date(args.lastUpdated).toString() : '',
                  args._id,
                ];
              default:
                return [];
            }
          }}
          rowKey="_id"
          pagination={pagination}
          mapRowLink={(row) => getOnboardingTemplateRoute(row._id)}
        />
      </>
    </Tabs>
  );
};
