import { useTaskHandlerPagination } from './useTaskHandlerPagination';

export const useSegmentsPagination = (
  args: {
    initialSelectedRows?: string[];
  } | void,
) =>
  useTaskHandlerPagination('segments', {
    orderBy: 'created',
    direction: 'desc',
    pagination: {
      limit: 10,
    },
    initialSelectedRows: args?.initialSelectedRows,
  });
