import styled from 'styled-components';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';

export const SignInPage: React.FC<{ onSignIn: () => void }> = ({ onSignIn }) => {
  return (
    <Box
      sx={{
        flex: 1,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '3rem',
      }}
    >
      <Intro>Account Portal</Intro>

      <Box
        sx={{
          display: 'flex',
          minWidth: '40%',
          flexDirection: 'column',
          marginTop: '5rem',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: ' 0.8rem',
          padding: '2rem',
          paddingBottom: '5rem',
          boxShadow: '1px 2px .5rem  #bdbdbd',
        }}
      >
        <Heading>Sign In</Heading>
        <Box>
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={onSignIn}
            disableTouchRipple
            type="submit"
          >
            TQ-SSO
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const Intro = styled.h1`
  font-size: 1.2rem;
  font-weight: 400;
`;

const Heading = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #344767;
  font-size: 1.2rem;
  line-height: 2rem;
`;
