import { TablePagination } from '@mui/material';

export * from './usePagination';

export type PaginationProps = {
  page: number;
  handleSetPage: (page: number) => void;
  limit: number;
  handleSetLimit: (limit: number) => void;
  count?: number;
};

export const Pagination = ({
  page = 1,
  handleSetPage,
  limit,
  handleSetLimit,
  count,
}: PaginationProps) => (
  <TablePagination
    rowsPerPageOptions={[2]}
    component="div"
    count={count || 0}
    rowsPerPage={limit}
    page={page}
    onPageChange={(event, page) => (handleSetPage ? handleSetPage(page) : undefined)}
    onRowsPerPageChange={(event) => handleSetLimit && handleSetLimit(Number(event.target.value))}
  />
);
