let config = {
  ssoBaseUrl: '',
  service: '',
  path: '',
};

const setConfig = (value: typeof config) => {
  config = value;
};

let appSignOut: () => Promise<void>;

export const signOut = async () => {
  try {
    await appSignOut();
    redirectToSignoutSSO();
  } catch (e) {
    console.log('error signing out', e);
  }
};

const redirectToSignoutSSO = () =>
  window.location.replace(`${config.ssoBaseUrl}/sign-out/?service=${config.service}`);

export const signIn = () => {
  window.location.replace(`${config.ssoBaseUrl}?service=${config.service}&path=${config.path}`);
};

export interface IntializeAuthParams {
  service: string;
  isSignedIn: () => Promise<boolean>;
  signIn: (params: { token: string; tenantId: string }) => void;
  signOut: () => Promise<void>;
  ssoBaseUrl: string;
}

const removeAuthRefreshTime = () => {
  localStorage.removeItem('authRefreshTime');
};

const setNewAuthRefreshTime = () => {
  localStorage.setItem('authRefreshTime', String(new Date().getTime()));
};
const oneMin = 10 * 1000;
const oneHour = oneMin * 60;
const getAuthRefreshTime = (): number => {
  return Number(localStorage.getItem('authRefreshTime'));
};

const hasAuthExpried = () => {
  const authRefreshTime = getAuthRefreshTime();
  if (!authRefreshTime) {
    return false;
  } else {
    return new Date().getTime() - getAuthRefreshTime() > oneHour;
  }
};

const refreshAuth = () => {
  // appSignOut();
  // signIn();
};

export const initializeAuth = ({
  service,
  signIn: signInParam,
  ssoBaseUrl,
  signOut: signOutParam,
  isSignedIn,
}: IntializeAuthParams): Promise<void> =>
  new Promise(async (resolve) => {
    const path = window.location.pathname;
    appSignOut = signOutParam;
    setConfig({
      ssoBaseUrl,
      service,
      path,
    });
    const tokenFromUrl = new URLSearchParams(window.location.search).get('token');

    if (tokenFromUrl) {
      const tenantId = new URLSearchParams(window.location.search).get('tenantId') as string;
      window.history.pushState('', '', path);
      try {
        await signInParam({ token: tokenFromUrl, tenantId });
        setNewAuthRefreshTime();
      } catch {
        console.log('tokenFromUrl error');
      }
    }
    const signedIn = await isSignedIn();
    if (hasAuthExpried() && signedIn) {
      removeAuthRefreshTime();
      refreshAuth();
    } else if (!signedIn) {
      if (path !== '/' && path !== '/sign-in') {
        removeAuthRefreshTime();
        console.log('Needs to sign in again');
        signIn();
      }
    }
    resolve();
  });
