import { useClientOrganizations } from '@cannabis/account-portal-core/hooks/useClientOrganizations';
import { useCreateOrganization } from '@cannabis/account-portal-core/hooks/useCreateOrganization';
import { FormContainer } from '@tq1992/components/FormContainer';
import { Table } from '@tq1992/components/Table';
import { Tabs } from '@tq1992/components/Tabs';
import { TextField } from '@tq1992/components/TextField';

import { ButtonLinkToPage } from './Navigation';

export const CreateOrganization = () => {
  const { submit, register } = useCreateOrganization();
  return (
    <Tabs options={['Create Organization']} appBar={true}>
      <FormContainer title="Create Organization" submitButtonLabel="Create" onSubmit={submit}>
        <TextField label="Name" {...register('name')} />
        <TextField label="Email" {...register('user.email')} />
        <TextField label="Password" {...register('user.password')} />
      </FormContainer>
    </Tabs>
  );
};

export const ClientOrganizations = () => {
  const { data, pagination, isLoading } = useClientOrganizations();
  return (
    <Tabs options={['Organizations']} appBar={true}>
      <>
        <ButtonLinkToPage
          to="CreateOrganization"
          label="Create Organization"
          iconify="ic:outline-add-home-work"
        />
        <Table
          isLoading={isLoading}
          headers={['_id', 'Name']}
          data={data.map((organization) => ({ ...organization, _id: organization._id }))}
          mapRow={({ _id, name }) => [_id, name]}
          rowKey="_id"
          pagination={pagination}
        />
      </>
    </Tabs>
  );
};
