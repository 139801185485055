import { getPushTemplateRoute } from '@cannabis/account-portal-core/AccountPortalAppConfigs';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { Table } from '@tq1992/components/Table';

import { ButtonLinkToPage } from '../../Navigation';

export const PushTemplates = () => {
  const { data, isLoading, pagination } = useTaskHandlerPagination('templates', {
    filters: [{ property: 'type', compare: '==', match: 'push' }],
  });
  return (
    <>
      <ButtonLinkToPage
        to="CreatePushTemplate"
        label="Create Template"
        iconify="ic:round-add-circle"
      />
      <Table
        pagination={pagination}
        rowKey="_id"
        headers={['Title', 'Title Message', 'Message', '_id']}
        mapRow={(data) => {
          if (data.type === 'push') {
            return [data.title, data.pushTitle, data.body, data._id];
          }
          return [];
        }}
        isLoading={isLoading}
        data={data || []}
        mapRowLink={(data) => getPushTemplateRoute(data._id)}
      />
    </>
  );
};
