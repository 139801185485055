import EmojiPicker from 'emoji-picker-react';
import React from 'react';
import * as Yup from 'yup';

import { useMutationFormCallAction } from '@cannabis/account-portal-core/hooks/useHandlerEvent';
import { useTaskHandlerPagination } from '@cannabis/account-portal-core/hooks/useTaskHandlerPagination';
import { Grid } from '@mui/material';
import { Accordion } from '@tq1992/components/Accordion';
import { Container } from '@tq1992/components/Container';
import { DateTimePicker } from '@tq1992/components/DateTimePicker';
import { FormContainer } from '@tq1992/components/FormContainer';
import { ApplePushNotificationPreview } from '@tq1992/components/PushNotificationPreview';
import { Select, useSelect } from '@tq1992/components/Select';
import { TextField } from '@tq1992/components/TextField';
import { ToggleButtonGroup } from '@tq1992/components/ToggleButtonGroup';

export const CreateAutomatedPush = () => {
  const { data: projects } = useTaskHandlerPagination('projects', { orderBy: 'name' });

  const {
    options: projectOptions,
    onChange: onChangeProject,
    value: projectSelectedValue,
  } = useSelect(
    [{ label: 'None', value: '' }].concat(
      projects?.map((project) => ({ value: project._id, label: project.name })) || [],
    ),
  );

  const { fields, register, submit, setValue, getValues, watch } = useMutationFormCallAction(
    'createAutomatedPush',
    {
      fields: [
        {
          _id: 'title',
          label: 'Title',
          schema: Yup.string().optional(),
        },
        {
          _id: 'body',
          label: 'Body',
        },
        {
          _id: 'date',
          label: 'Time',
        },
        {
          _id: 'daysOfTheWeek',
          label: 'Days of the week',
          schema: Yup.array(),
          value: [],
        },
      ],
    },
    {
      projectId: projectSelectedValue,
      successMessage: () => 'message created',
      type: 'PUSH',
    },
  );
  const renderedFields = React.useMemo(
    () =>
      fields.map((field) => {
        switch (field._id) {
          case 'date':
            return (
              <DateTimePicker
                type="time"
                key={field._id}
                onChange={(value) => setValue(field._id, value)}
              />
            );
          case 'daysOfTheWeek':
            return (
              <ToggleButtonGroup
                label="Days of the week"
                options={[
                  {
                    label: 'Monday',
                    value: 0,
                  },
                  {
                    label: 'Tuesday',
                    value: 1,
                  },
                  {
                    label: 'Wednesday',
                    value: 2,
                  },
                  {
                    label: 'Thursday',
                    value: 3,
                  },
                  {
                    label: 'Friday',
                    value: 4,
                  },
                  {
                    label: 'Saturday',
                    value: 5,
                  },
                  {
                    label: 'Sunday',
                    value: 6,
                  },
                ]}
                onChange={(newValues) => setValue(field._id, newValues)}
              />
            );
          default:
            return (
              <React.Fragment key={field._id}>
                <TextField key={field._id} label={field.label} {...register(field._id)} />
                <Accordion header="Emoji Keyboard 🍃🔥💨">
                  <EmojiPicker
                    key={field._id}
                    onEmojiClick={(emoji) => {
                      setValue(field._id, `${getValues(field._id)}${emoji.emoji}`);
                    }}
                    height={350}
                    previewConfig={{ showPreview: false }}
                  />
                </Accordion>
              </React.Fragment>
            );
        }
      }),
    [],
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <FormContainer title="Create Daily Push" onSubmit={submit} submitButtonLabel="Create">
          {renderedFields}
          <Select
            options={projectOptions}
            onChange={onChangeProject}
            value={projectSelectedValue}
            label={'Project'}
          />
        </FormContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Container noBackground={true}>
          <ApplePushNotificationPreview
            title={watch('title')}
            message={watch('body')}
            time={'Now'}
          />
        </Container>
      </Grid>
    </Grid>
  );
};
