// import { XMLValidator } from 'fast-xml-parser';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';

export const EmailPreview = ({ html }: { html: string }) => {
  const [validPreview, setValidPreview] = useState('');
  useEffect(() => {
    if (html) {
      //   const valid = XMLValidator.validate(html);
      const valid = true;
      if (valid === true) {
        setValidPreview(html);
      }
    }
  }, [html]);
  return (
    <div
      style={{
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'grey',
      }}
    >
      {parse(validPreview)}
    </div>
  );
};
